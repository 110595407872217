import React from 'react';
import toast from 'react-hot-toast';
import translations from '../translations';

export function questionnaireParser(currentQuestion, answer, setErrMsg) {
    const { label, type } = currentQuestion;
    let answerString = Array.isArray(answer) && answer.length > 1 ? answer.join('') : answer;

    if (!answerString || answerString.length < 1 || !answerString[0]){
        toast.error('Please fill the required fields');
        return false;
    }
    if (type === 'number') {
        if (answerString && isNaN(answerString) || answerString[0] === '0' || /\s/.test(answerString)) {
            toast.error('Invalid input');
            console.log(answerString[0]);
            return false;
        }
        if (label === 'age') {
            if (answerString && answerString < 18 || answerString > 160 || /\s/.test(answerString)) {
                toast.error('18 is the minimum age and 160 is the maximum age');
                return false;
            }
        } else if (label === 'weight') {
            if (answerString && answerString < 10 || answerString > 200 || /\s/.test(answerString)) {
                toast.error('Invalid weight');
                return false;
            }
        } else if (label === 'height') {
            if (answerString && answerString < 40 || answerString > 273 || /\s/.test(answerString)) {
                toast.error('Invalid height');
                return false;
            }
        }
    } else if (type === 'single-choice') {
        if (!answerString || answerString.length < 1 || answerString.length > 350 || /^\s*$/.test(answerString)) {
            toast.error('Please select an answer');
            return false;
        }
    } else if (type === 'multiple-choice') {
        if (!answerString || answerString.length < 1 || /^\s*$/.test(answerString)) {
            toast.error('Please select or specify an answer');
            return false;
        }else if (answerString && answerString.length > 350) {
            toast.error('Maximum length is 350 characters');
            return false;
        }
    } else if (type === 'text') {
        if (answerString && answerString.length > 350) {
            toast.error('Maximum length is 300 characters');
            return false;
        }
    }
    return true;
}
