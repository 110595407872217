import React from 'react';

const TypingAnimation = () => {
    return (
    <div className="typing-indicator">
        <div className="typing-circle"></div>
        <div className="typing-circle"></div>
        <div className="typing-circle"></div>
        <div className="typing-shadow"></div>
        <div className="typing-shadow"></div>
        <div className="typing-shadow"></div>
    </div>
    );
};


export default TypingAnimation;