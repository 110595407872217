import React, { useState } from 'react';
import { ReactComponent as CharacterSvg } from './Character.svg';
import '../style/CharacterInteractive.css';

const CharacterInteractive = () => {
  const [hoveredPart, setHoveredPart] = useState(null);

  // const handleMouseEnter = (part) => {
  //   setHoveredPart(part);
  // };

  // const handleMouseLeave = () => {
  //   setHoveredPart(null);
  // };

  return (
    <div className="character-container">
      {/* <CharacterSvg
        className="character-svg"
        onMouseLeave={handleMouseLeave}
      />
      <div className="tooltip" style={{ display: hoveredPart ? 'block' : 'none' }}>
        {hoveredPart && (
          <p>
            {hoveredPart === 'head' && 'This is the head of the character'}
            {hoveredPart === 'body' && 'This is the body of the character'}
            {hoveredPart === 'left-arm' && 'This is the left arm'}
            {hoveredPart === 'right-arm' && 'This is the right arm'}
            {hoveredPart === 'left-leg' && 'This is the left leg'}
            {hoveredPart === 'right-leg' && 'This is the right leg'}
          </p>
        )}
      </div> */}
    </div>
  );
};

export default CharacterInteractive;
