// UploadButton.js
import React, { useState, useRef } from 'react';
import { FiUploadCloud } from 'react-icons/fi';
import { useData } from './api/store';
import { Parsing_file_input } from './Parsing_file_input';
import LongConsent from './LongConsent';
import translations from './translations';
import toast from 'react-hot-toast';

const UploadButton = ({ setFiles , navigate}) => {
  const [ConsentStateChange, setConsentStateChange] = useState(false);
  const [errState, setErrState] = useState(false);
  const fileInputRef = useRef(null);
  const {
    consent,
    setConsent,
    setIsFileUploaded,
    setSignInForVisitor,
    activeLang,
    IspreFilled,
    setimNotFeelingWell,
    isLoggedIn
  } = useData();
  const lang = translations[activeLang];
  
  const handleClick = () => {
    if (!isLoggedIn){
      toast.error(lang.UserNotLoggedIn);
      navigate("/SignLogin");
      return ;
    }
    if (fileInputRef.current) {
      const newInput = document.createElement('input');
      newInput.type = 'file';
      newInput.accept = '.jpg,.jpeg,.png,.pdf';
      newInput.multiple = true;
      newInput.style.display = 'none';
      newInput.onchange = handleChange;
      document.body.appendChild(newInput);
      newInput.click();
    } else {
      fileInputRef.current.click();
    }
  };

  const handleChange = async (event) => {
    if (!isLoggedIn){
      toast.error(lang.UserNotLoggedIn);
      navigate("/SignLogin");
      return ;
    }
    if (Parsing_file_input(event, setIsFileUploaded, setErrState, errState) === false) {
      return;
    } else {
      const formData = new FormData();
      Array.from(event.target.files).forEach((file) => {
        formData.append('uploaded_file', file);
      });
      setFiles(formData);
      setIsFileUploaded(true);
      if (IspreFilled)
          navigate("/PreFilledQuestions");
      else
        navigate("/Questionnaire");
        
    }
  };

  const handleCheckboxChange = () => {
    setConsentStateChange(false);
    setConsent(true);
  };

  const handleReload = (event) => {
    event.preventDefault();
    setConsentStateChange(false);
  };
  const handleImNotFeelinWell = () => {
    if (!isLoggedIn){
      toast.error(lang.UserNotLoggedIn);
      navigate("/SignLogin");
      return ;
    }
    setIsFileUploaded(true);
    setimNotFeelingWell(true);
    
    // Always navigate to UnifiedQuestionnaire for both prefilled and new users
    navigate("/Questionnaire");
  }

  return (
    <div className="uploud-btn-div">
      {ConsentStateChange && (
        <LongConsent handleCheckboxChange={handleCheckboxChange} handleReload={handleReload} />
      )}
      <input ref={fileInputRef} onChange={handleChange} style={{ display: 'none' }} multiple />
      <p className='warning-info'>{lang.warning}</p>
      <div className='main-btns-div'>
      <button className="btn-upload" onClick={handleClick}>
        <span>{lang.uploadButton}</span>
        <i className="fa-solid fa-cloud-arrow-up"></i>
      </button>
      <button className="btn-feeling" onClick={handleImNotFeelinWell}>
          <span>
            {lang.notFeelingWell}
          </span>
          <i className="fa-regular fa-face-tired"></i>
        </button>
      </div>
    </div>
  );
};

export default UploadButton;
