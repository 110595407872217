import React from 'react';
import { useEffect , useRef } from 'react';
import { useData } from '../api/store';

export const NumberInput = ({ question, currentAnswer, setAnswers, currentQuestionIndex }) => {
  const inputRef = useRef(null);
  const { IspreFilled } = useData();
  useEffect(() => {
    if (inputRef.current)
      inputRef.current.focus();
  }, [currentAnswer, setAnswers]);

  const handleInputBlur = () => {
    if (inputRef.current)
      inputRef.current.focus();
  };

  return (
    <div key={question.id} className='fade-in'>
      <label className='label-q fade-in'>{question.text}</label>
      <input
        type="number"
        name={`${IspreFilled ? question.label : "answer"}`}
        ref={!IspreFilled ? inputRef : null}
        onBlur={handleInputBlur}
        defaultValue={currentAnswer?.[0] || ''} 
        onChange={(e) => setAnswers((prevAnswers) => ({
          ...prevAnswers,
          [currentQuestionIndex]: [e.target.value],
        }))}
        required
      />
    </div>
  );
};
