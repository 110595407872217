import React, { useState, useEffect, useRef } from 'react';
import '../style/TextToSpeech.css';

const TextToSpeech = ({ text, language }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const audioRef = useRef(null);
  const waveIndicatorRef = useRef(null);

  // Function to generate speech using ElevenLabs API
  const generateSpeech = async () => {
    if (audioUrl) {
      // If we already have audio, just play it
      audioRef.current.play();
      setIsPlaying(true);
      return;
    }

    setLoading(true);
    try {
      // Configure voice model based on the language
      const voiceId = language === 'ar' 
        ? 'pFZP5JgTPZH1UJk8O8iN' // Arabic voice ID
        : 'EXAVITQu4vr4xnSDxMaL'; // English voice ID (Josh)

      const response = await fetch('https://api.elevenlabs.io/v1/text-to-speech/' + voiceId, {
        method: 'POST',
        headers: {
          'Accept': 'audio/mpeg',
          'Content-Type': 'application/json',
          'xi-api-key': 'sk_c052da12123d1c33052b8dc75bbb0a082b8bdab2806a1fa8', // Replace with your actual API key
        },
        body: JSON.stringify({
          text: text,
          model_id: 'eleven_multilingual_v2',
          voice_settings: {
            stability: 0.5,
            similarity_boost: 0.75,
            style: 0.0,
            use_speaker_boost: true,
          }
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate speech');
      }

      const audioBlob = await response.blob();
      const url = URL.createObjectURL(audioBlob);
      setAudioUrl(url);
      
      // Play the audio after it's loaded
      audioRef.current.src = url;
      audioRef.current.play();
      setIsPlaying(true);
    } catch (error) {
      console.error('Error generating speech:', error);
      alert('Failed to generate speech. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  // Toggle play/pause
  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      generateSpeech();
    }
  };

  // Handle audio end
  const handleAudioEnd = () => {
    setIsPlaying(false);
  };

  // Animation for the audio waves
  useEffect(() => {
    if (isPlaying && waveIndicatorRef.current) {
      const waves = waveIndicatorRef.current.querySelectorAll('.wave');
      waves.forEach((wave, index) => {
        wave.style.animation = `tts-specific-wave ${1 + index * 0.2}s ease-in-out infinite alternate`;
      });
    } else if (waveIndicatorRef.current) {
      const waves = waveIndicatorRef.current.querySelectorAll('.wave');
      waves.forEach(wave => {
        wave.style.animation = 'none';
      });
    }
  }, [isPlaying]);

  // Clean up the audio URL when component unmounts
  useEffect(() => {
    return () => {
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
    };
  }, [audioUrl]);

  return (
    <div className="text-to-speech-container">
      <button 
        className={`tts-button ${isPlaying ? 'playing' : ''} ${loading ? 'loading' : ''}`} 
        onClick={togglePlay}
        disabled={loading}
        aria-label={isPlaying ? "Pause speech" : "Play speech"}
      >
        {/* Pulse rings animation when playing */}
        {isPlaying && (
          <div className="pulse-container">
            <div className="pulse-ring"></div>
            <div className="pulse-ring"></div>
            <div className="pulse-ring"></div>
          </div>
        )}
        
        {loading ? (
          <div className="loading-spinner"></div>
        ) : isPlaying ? (
          <i className="fas fa-pause"></i>
        ) : (
          <i className="fas fa-play"></i>
        )}
        
        {/* Wave indicator */}
        <div className="wave-indicator" ref={waveIndicatorRef}>
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
        </div>
      </button>
      
      {/* Label below the button */}
      {/* <span className="listen-label">
        {language === 'ar' ? 'استمع' : 'Listen'}
      </span> */}
      
      <audio ref={audioRef} onEnded={handleAudioEnd} />
    </div>
  );
};

export default TextToSpeech; 