// src/LanguageSwitcher.js
import React from 'react';
import { useData } from './api/store';
import axios from './api/axios';
import './style/LanguageSwitcher.css';
import toast from 'react-hot-toast';
import translations from './translations';
const SET_LANG_URL = process.env.REACT_APP_BASE_URL + '/api/set_language/'

function LanguageSwitcher() {
  const { activeLang, setActiveLang , isLoggedIn} = useData();
  const lang = translations[activeLang];
  const handleLanguageChange =  async (language) => {
    setActiveLang(language);
    if(isLoggedIn)
    {
      try {
        const response = await axios.post(SET_LANG_URL,{
            language: language,
          }, {
          headers:{
            'Content-Type' : "application/json",
          },
          withCredentials: true,
        });
        if (response.status === 201)
          setActiveLang(response.data.language);
      }catch(error){
        if (error.response){
          if (error.response.status === 403)
            toast.error(lang.UserNotLoggedIn);
          else if (error.response.status === 400)
            toast.error(lang.NotSupported);
          else if (error.response.status === 500)
            toast.error(lang.DatabaseError);
        } else
          toast.error(lang.NetworkError);
      }
    }
  };

  return (
    <div className="language-switcher">
      <div className="language-options">
      <div className={`slider ${activeLang === 'en' ? 'slide-en' : 'slide-ar'}`}></div>
        <button
          className={`language-option ${activeLang === 'en' ? 'activelang' : ''}`}
          onClick={() => handleLanguageChange('en')}
        >
          English
        </button>
        <button
          className={`language-option ${activeLang === 'ar' ? 'activelang' : ''}`}
          onClick={() => handleLanguageChange('ar')}
        >
          عربي
        </button>
      </div>
    </div>
  );
}

export default LanguageSwitcher;
