import React, { useState , useEffect} from 'react';
import '../style/SettingsPanel.css';
import Profile from './Profile';
import General from './General';
import { useNavigate } from 'react-router-dom';
import { useData } from '../api/store';
import toast from 'react-hot-toast';
import axios from 'axios';
import LanguageRegion from './LanguageRegion';
import DeleteModal from './DeleteModal';
import LoadingPage from '../LoadingPage';
import translations from '../translations';
import { GoogleLogout } from 'react-google-login';
const LOGOUT_URL = process.env.REACT_APP_BASE_URL + "/api/logout_user/";


const SettingsPanel = ({ toggleSettings }) => {
  const [activeTab, setActiveTab] = useState('profile');
  const {setLoggedIn  , setUploadedFiles, user , email , loadingPage , setLoadingPage , setConsent , activeLang} = useData();
  const [name, setName] = useState(user);
  const [modalDeleteConsent, setModalDeleteConsent] = useState(false);
  const navigate = useNavigate();
  const lang = translations[activeLang];


  const handleLogout = async () => {
    setLoadingPage(true);
    try {
      const response = await axios.get(LOGOUT_URL,{
        withCredentials: true,
      });
      if (response.status === 200) {
        setLoadingPage(false);
        document.cookie.split(";").forEach((c) => {
          document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
        // if (response.data.vendor === 'google') {
        //     window.gapi.auth2.getAuthInstance().signOut();
        //   }
        // else if (response.data.vendor === 'facebook') {
        //     window.FB.logout();
        // }
        setConsent(false);
        setLoggedIn(false);
        setUploadedFiles([]);
        toggleSettings();
        toast.success(lang.LoggoutMsg);
        window.location.reload();
      } else if (response.status === 403) {
        toast.error(lang.UserNotfound);
        throw new Error('Error during logout');
      }
    } catch (error) {
      setLoadingPage(false);
      toast.error(lang.NetworkError);
    }
  };
  
  const renderContent = () => {
    switch (activeTab) {
      case 'profile':
        return <Profile />;
      case 'general':
        return <General />;
      case 'language':
        return <LanguageRegion />;
      default:
        return null;
    }
  };

  useEffect(() => {
    const profileSection = document.getElementById('profileSection');

    if (profileSection) {
      Array.from({ length: 10 }).forEach((_, i) => {
        const dot = document.createElement('div');
        dot.classList.add('dot');
        dot.style.setProperty('--i', i + 1);
        dot.style.left = `${Math.random() * 100}%`;
        dot.style.animationDelay = `${Math.random() * 5}s`;
        profileSection.appendChild(dot);
      });
    }
  }, []);

  const handleShowDeleteConsent = () => { 
      setModalDeleteConsent(true);
  };

  useEffect(() => {
    const settingsSection = document.getElementById('settingsSection');

    if (settingsSection) {
      Array.from({ length: 10 }).forEach((_, i) => {
        const dot = document.createElement('div');
        dot.classList.add('dot');
        dot.style.setProperty('--i', i + 1);
        dot.style.left = `${Math.random() * 100}%`;
        dot.style.animationDelay = `${Math.random() * 5}s`;
        settingsSection.appendChild(dot);
      });
    }
  }, []);
  return (
    <>
    {loadingPage && <LoadingPage />}
    <div className="settings-panel">
      {modalDeleteConsent && <DeleteModal setModalDeleteConsent={setModalDeleteConsent} />}
      <div className="row">
        <div className="col-lg-4  profile-section" id="profileSection">
      <button className="close-btn" onClick={toggleSettings}><i className="fa-solid fa-circle-xmark"></i></button>
          <div className="profile-section-sontent">
          <i className="fas fa-user-circle avatar-icon-large"></i>
          <h3>{name}</h3>
          <p>{email}</p>
          <span className='delete-account-btn' onClick={handleShowDeleteConsent}> {lang.deleteAccount} <i className="fa fa-trash" ></i></span>
          </div>
        </div>
        <div className={`col-lg-8 settings-section ${activeLang === 'ar' ? 'onlyRtl' : ''}`} id="settingsSection">
          <div className="settings-header">
            <h1>{lang.settingTitle}</h1> 
            <span className={`logout-btn ${activeLang === 'ar' ? 'mostLeft' : ''} `} onClick={handleLogout}> {lang.logoutBtn} <i className="fa fa-sign-out" ></i></span>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a className={`nav-link ${activeTab === 'profile' ? 'active' : ''}`} onClick={() => setActiveTab('profile')}>{lang.profileSett}</a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${activeTab === 'general' ? 'active' : ''}`} onClick={() => setActiveTab('general')}>{lang.GeneralSett}</a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${activeTab === 'language' ? 'active' : ''}`} onClick={() => setActiveTab('language')}>{lang.LanguageRegionSett}</a>
              </li>
            </ul>
          </div>
          {renderContent()}
        </div>
      </div>
    </div>
    </>
  );
};

export default SettingsPanel;
