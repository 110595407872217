import {jwtDecode} from 'jwt-decode';
import { useData } from '../api/store';
import toast from 'react-hot-toast';
import { setAuthTokenCookie } from './setAuthTokenCookie';
import axios from 'axios';
import translations from '../translations';
const GET_USER_TOKEN = process.env.REACT_APP_BASE_URL + "/api/login_user/";

export async function handleGoogleLogin(credentialResponse , navigate) {
  const {activeLang , signInForVisitor , setSignInForVisitor , setLoggedInUser , setSignUpActive} = useData.getState();
  let { credential } = credentialResponse;
  const decodedToken = jwtDecode(credential);
  const { email } = decodedToken;
  const lang = translations[activeLang];

  try {
    const response = await axios.post(GET_USER_TOKEN, {
      vendor: "google",
      email: email,
      access_token: credential,
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
    if (response.status === 200 || response.status === 201) {
      setAuthTokenCookie(response.data.token); 
      setLoggedInUser({
        username : response.data.name,
        email : response.data.email,
      })
      toast.success(lang.LoggedIn);
      if (signInForVisitor)
      {
        setSignInForVisitor(false);
        return ;
      } else {
        navigate(-1);
      }
    }
  } catch (error) {
  if (error.response){
    if (error.response.status === 404) {
      toast.error(lang.UserNotfound);
      setSignUpActive(true);
      } else if (error.response.status === 403) {
          toast.error(lang.Forbidden);
      } else if (error.response.status === 400) {
          toast.error(lang.BadRequest);
      } else if (error.response.status === 500)
          toast.error(lang.ServerError);
  }else
      toast.error(lang.NetworkError);
  }
}
