import React, { useEffect, useState } from 'react';
import { useData } from '../api/store';
import lodingimage from '../../res/LoadingResult.gif';
import '../style/LoadingResults.css';
import translations from '../translations';
import LandingPage from '../LandingPage';

const LoadingResults = () => {
  const [progress, setProgress] = useState(0);
  const { activeLang } = useData();
  const lang = translations[activeLang];

  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + (100 / 240); 
        return newProgress >= 100 ? 100 : newProgress; 
      });
    }, 1000);

    return () => clearInterval(progressInterval); 
  }, []);

  return (
    <div className="loading-container">
      <img className="imgLoading" src={lodingimage} alt="Loading" />
      <div className="slideshow">
        <p>{lang.LoadingResults}</p>
      </div>
      <div className="progress-bar-container">
        <div
          className="progress-bar"
          style={{ width: `${progress}%` , backgroundColor : "#01a4c0" }}
        ></div>
      </div>
    </div>
  );
};

export default LoadingResults;
