import React, { useState, useEffect} from 'react';
import { useData } from '../api/store';

export const SingleChoice = ({ question, currentAnswer, setAnswers, currentQuestionIndex, specifyOptions}) => {
  const { otherText, handleOtherChange } = specifyOptions;
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const { activeLang , IspreFilled , setIsGenderChanged} = useData();
  const [ oldChoice , setOldChoice ] = useState(currentAnswer?.[0] || "")

  useEffect(() => {
    if (currentAnswer?.length === 0 && question.options.length > 0) {
      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        [currentQuestionIndex]: [question.options[0]],
      }));
    }
  
    if (currentAnswer && (currentAnswer?.includes('Female') || currentAnswer?.includes('Male'))) {
      if (currentAnswer?.[0] === oldChoice) {
        return ;
      }
      setOldChoice(currentAnswer[0]);
      setIsGenderChanged(true);
    }
  
    if (currentAnswer?.includes('Other (please specify)') || currentAnswer?.includes('أخرى (يرجى التحديد)')) {
      currentAnswer = currentAnswer.filter((ans) => ans !== 'Other (please specify)' && ans !== 'أخرى (يرجى التحديد)');
      setIsOtherSelected(true);
    } else {
      setIsOtherSelected(false);
    }
  }, [question.options, currentQuestionIndex, currentAnswer, oldChoice]);

  return (
    <div key={question.id} className="radio-container fade-in">
      <label className='label-q fade-in'>{question.text}</label>
      {question.options.map((option, index) => (
        <div key={index}>
          {(option === 'Other (please specify)' || option === 'أخرى (يرجى التحديد)')  && isOtherSelected ? (
            <>
            <input
              type="radio"
              id={`${question.label}-${index}`}
              checked={currentAnswer.includes(option)}
            />
            <input
            type="text"
            name={`${IspreFilled ? question.label : "answer"}`}
            value={otherText}
            onChange={handleOtherChange}
            placeholder={activeLang  === 'en' ? "Please specify" : "يرجى التحديد"}
            className="other-input"
            style={{ display: 'block' }}
            />
            </>
          ) : (
            <>
            <input
            type="radio"
            id={`${question.label}-${index}`}
            name={`${IspreFilled ? question.label : "answer"}`}
            value={option}
            checked={currentAnswer?.includes(option)}
            onChange={() => setAnswers((prevAnswers) => ({
              ...prevAnswers,
              [currentQuestionIndex]: [option],
            }))}
            />
            <label htmlFor={`${question.label}-${index}`}>{option}</label>
            </>
        )}
        </div>
      ))}
    </div>
  );
};