import React from 'react';
import { useEffect , useState} from 'react';
import { SignUp } from './SignUp';
import { Login } from './Login';
import { useData } from '../api/store';
import { checkIsLogin } from './checkIsLogin';
import toast from 'react-hot-toast';
import '../style/Sign-login.css';
import { useNavigate } from 'react-router-dom';
import translations from '../translations';

function SignLoginMobile() {
  const { isSignUpActive, setSignUpActive, otpShow , isLoggedIn, activeLang} = useData();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();
  const lang = translations[activeLang];

  useEffect(() => {
    const checkLoginStatus = async () => {
      const isLoggedIn = await checkIsLogin();
      if (isLoggedIn) {
        toast.success(lang.AlreadyLoggedIn);
        window.location.href = '/LandingPage';
      }
    };
    checkLoginStatus();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isLoggedIn, navigate]);

  const handleClick = () => {
    setSignUpActive(!isSignUpActive);
  };

  return (
    <>
    <div className='mobile-toggle-div'>
    </div>
    <div className='parent-container-sign-up-in'>
      <div className='container-sign-up-in'>
        <div className="mobile-toggle-buttons">
        {isSignUpActive ? <SignUp /> : <Login />}
        </div>
        <button
        className="mobile-btn-toggle" 
        onClick={handleClick} 
        disabled={otpShow}
        > 
        <div className='SignIn-Mobile-btn-toggle-text-div'>
        {isSignUpActive && <><span className='SignIn-Mobile-btn-toggle-text'>{lang.signUpMobile}</span> <span className='SignIn-Mobile-btn-toggle'>{lang.signIn}</span></>}
        {!isSignUpActive && <><span className='SignIn-Mobile-btn-toggle-text'>{lang.signInMobile}</span> <span className='SignIn-Mobile-btn-toggle'>{lang.signUp}</span></>}
        </div>
        </button>
      </div>
    </div>
    </>
  );
}

export default SignLoginMobile;
