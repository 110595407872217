import React, {useState} from 'react';
import FormLoading from '../Questionnaire/FormLoading';
import summaryImage from '../../res/summary.png'
import lineSegment from '../../res/line-segment.png'
import { useData } from '../api/store';
import translations from '../translations';
import TextToSpeech from './TextToSpeech';

const Summary = ({ summary, resultFavLang }) => {
    const { activeLang } = useData();
    const lang = translations[activeLang];
    
    // Clean summary text from special characters/formatting for TTS
    const cleanSummaryForTTS = () => {
      if (typeof summary !== 'string') return '';
      
      // Remove any special characters or markdown that might affect TTS
      return summary.replace(/\*/g, '')
                   .replace(/\n\n/g, '. ')
                   .replace(/\n/g, '. ');
    };
    
    return (
      <div className={`container summary ${resultFavLang === 'ar' ? 'flex-row-reverse' : ''}`}>
          {typeof summary === 'string' && summary ?
          <div className={`row ${resultFavLang === 'ar' ? 'onlyRtl' : ''}`}>
              <div className='col-lg-12'>
                  <div className="summary-header">
                      <h1>{lang.summary}</h1>
                  </div>
                  
                  {/* TTS centered above content */}
                  <div className="summary-tts-wrapper">
                      <TextToSpeech text={cleanSummaryForTTS()} language={resultFavLang} />
                  </div>
              </div>
              <div className='col-lg-9'>
                  <p>{summary.split('\n'||'\r'||'\r\n').map((line, index) => (
                  <React.Fragment key={index}>
                      {line}
                      <br />
                  </React.Fragment>
                  ))}</p>
              </div>
             <div className='col-lg-3'>
                  <img src={summaryImage} className='summary-image' alt="Summary illustration" />
                  <img src={lineSegment} className='lineSegment-icon-summary' alt="Line decoration" />
              </div>
          </div>
              : <FormLoading />
              }
      </div>
    );
}

export default Summary;
