import axios from 'axios';
import { useData } from '../api/store';
import { setAuthTokenCookie } from './setAuthTokenCookie';
import toast from 'react-hot-toast';
import translations from '../translations';
const SEND_USER_OTP = process.env.REACT_APP_BASE_URL + "/api/send_login_otp/";

export async function sendOtpLogin(navigate) {
    const {activeLang , email, validEmail, setErrMsg, setSuccessMsg, setOtpShow, setOtpToken , setSignUpActive} = useData.getState();
    const lang = translations[activeLang];

    if (validEmail && typeof email === 'string' && email.length > 0) {
        try {
            const otpResponse = await axios.post(SEND_USER_OTP, { email });
            const { status, data } = otpResponse;

            if (status === 200 || status === 202 || status == 201) {
                const { token } = data;
                if (typeof token === 'string' && token.length > 0) {
                    setSuccessMsg(lang.OtpMsgCheckEmail);
                    toast.success(lang.OtpMsgCheckEmail);
                    setOtpToken(token);
                    setAuthTokenCookie(token);
                    setOtpShow(true);
                } else {
                    throw new Error("Invalid token received");
                }
            }
        } catch (error) {
        if (error.response){
            if (error.response.status === 400)
                toast.error(lang.UserNotfound);
            else if (error.response.status === 400)
                toast.error(lang.BadRequest);
         } else
                toast.error(lang.NetworkError);
            setSignUpActive(true);
        }
    } else {
        toast.error(lang.UserCreationFailed);
        navigate('/SignLogin');
    }
}
