import React, { useState } from 'react';
import { useData } from './api/store';
import DOMPurify from 'dompurify';
import toast from 'react-hot-toast';
import axios from 'axios';
import LoadingPage from './LoadingPage';
import './style/FeedbackModal.css';
import translations from './translations';
const CONTACT_US_URL = process.env.REACT_APP_BASE_URL + "/api/contact_us/";


const FeedbackModal = ({ isOpen, onRequestClose }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { activeLang } = useData();
  const lang = translations[activeLang];
  const EMAIL_REGX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  if (!isOpen) {
    return null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const sanitizedEmail = DOMPurify.sanitize(email);
    const sanitizedMessage = DOMPurify.sanitize(message);

    if (!sanitizedEmail.trim() || !sanitizedMessage.trim()) {
      setError('Email and message cannot be empty.');
      return;
    }
    else if (!EMAIL_REGX.test(sanitizedEmail)){
      toast.error("Invalid Email Address.")
      return;
    }
    else if (sanitizedMessage.length > 500)
    {
       toast.error("Message is too long");
       return;
    }
    setIsLoading(true);
    try {
      const respones = await axios.post(CONTACT_US_URL, {
        email: sanitizedEmail,
        body : sanitizedMessage,
      });
      if (respones.status === 200) {
        setIsLoading(false);
        setEmail('');
        setMessage('');
        toast.success("Your message is sent successfully, Thank you for sharing your thoughts with us, we will be in touch soon !");
      } else {
        throw new Error("Error during sending message");
      }
  } catch (error) {
      setIsLoading(false);
      setError("Something went wrong, Please try again later");
    toast.error("Something went wrong, Please try again later");
  }
    onRequestClose();
  };

  return (
    <>
     <div className={`modal-overlay ${activeLang ? "rtl" : ""}`} onClick={onRequestClose}>
    {isLoading && <LoadingPage />}
     <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>{lang.shareYourFeedBack}</h2>
        <p>{lang.WeWouldLikeTohearThought}</p>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">{lang.email}</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError('');
              }}
              required
              placeholder={lang.emailPlaceholder}
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">{lang.Message}</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
                setError('');
              }}
              required
              placeholder={lang.messagePlaceholder}
            ></textarea>
          </div>
          <button type="submit" className="submit-button">{lang.buttonText}</button>
        </form>
      </div>
    </div>
    </>
  );
};

export default FeedbackModal;
