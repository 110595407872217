import React from 'react';
import { BsDribbble, BsFacebook, BsGithub, BsInstagram, BsTwitter } from "react-icons/bs";
import './style/Footer.css'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-grid">
          <div className="footer-section">
            <h3 className="footer-title">Tahlili Solutions</h3>
            <ul className="footer-links">
              <li><a href="https://www.tahlili-solutions.com/AboutUs" target="_blank" rel="noopener noreferrer">About</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3 className="footer-title">Help Center</h3>
            <ul className="footer-links">
              <li><a href="https://www.instagram.com/p/C_OkAIxs1Qr/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" target="_blank" rel="noopener noreferrer">Instagram</a></li>
              <li><a href="https://x.com/TahliliSol/status/1828898590032314753" target="_blank" rel="noopener noreferrer">X - Twitter</a></li>
              <li><a href="https://www.facebook.com/profile.php?id=61558580654350" target="_blank" rel="noopener noreferrer">Facebook</a></li>
              <li><a href="https://www.tahlili-solutions.com/ContactUs" target="_blank" rel="noopener noreferrer">Contact Us</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3 className="footer-title">Legal</h3>
            <ul className="footer-links">
              <li><a href="https://www.tahlili-solutions.com/PrivacyPolicy#privacy_policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
              <li><a href="https://www.tahlili-solutions.com/PrivacyPolicy#terms_and_conditions" target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a></li>
              <li><a href="https://www.tahlili-solutions.com/PrivacyPolicy#consent" target="_blank" rel="noopener noreferrer">User &amp; Consent</a></li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 Tahlili</p>
          <div className="footer-social-icons">
            <a href="https://www.facebook.com/profile.php?id=61558580654350" target="_blank" rel="noopener noreferrer"><BsFacebook /></a>
            <a href="https://www.instagram.com/p/C_OkAIxs1Qr/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" target="_blank" rel="noopener noreferrer"><BsInstagram /></a>
            <a href="https://x.com/TahliliSol/status/1828898590032314753" target="_blank" rel="noopener noreferrer"><BsTwitter /></a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
