import { useData } from '../api/store';
import axios from '../api/axios';
import toast from 'react-hot-toast';
import { setAuthTokenCookie } from './setAuthTokenCookie';
import translations from '../translations';
const GET_USER_TOKEN = process.env.REACT_APP_BASE_URL + "/api/register_user/";

export async function handleUserSignUp(navigate, otpString) {
    const {activeLang , email, user, setErrMsg, setOtpShow, setSignUpActive, setLoggedIn , setUser, setEmail,signInForVisitor , setSignInForVisitor , consent , setConsent} = useData.getState();
    const lang = translations[activeLang];

    if (!email || !user || !consent) {
        setErrMsg(lang.ErrUserName);
        return;
    }
    try {
        const userTokenResponse = await axios.post(GET_USER_TOKEN, {
            vendor: "email",
            email: email,
            access_token: otpString,
            name: user,
            consented: "True",
        }, {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        });

        if (userTokenResponse.status === 201 || userTokenResponse.status === 200) {
            setAuthTokenCookie(userTokenResponse.data.token);  
            setOtpShow(false);
            setSignUpActive(false);
            setEmail(userTokenResponse.data.email);
            setUser(userTokenResponse.data.name);
            setConsent(true);
            setLoggedIn(true);
            toast.success(lang.LoggedIn);
            if (signInForVisitor)
            {
                setSignInForVisitor(false);
                return ;
            } else {
                navigate(-1);
            }
        } else {
            toast.error(lang.BadRequest);
            // setOtpShow(false);
            setSignUpActive(true);
        }
    } catch (error) {
    if (error.response){
        if (error.response.status === 409){
            toast.error(lang.AlreadySignedIn)
            setSignUpActive(false);
        } else if (error.response.status === 403) {
            toast.error(lang.Forbidden);
        } else if (error.response.status === 400) {
            toast.error(lang.BadRequest);
        } else if (error.response.status === 500)
            toast.error(lang.ServerError);
    } else {
        toast.error(lang.NetworkError);
    }  
    }
}
