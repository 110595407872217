import React, { useState, useEffect, useRef } from "react";
import { useData } from "../api/store";
import { flattenQuestions, categoryIcons } from "./helpers";
import { Progress } from "./Progress";
import { Question } from "./Question";
import LoadingResults from "./LoadingResults";
import LoadingSpinner from "./FormLoading";
import { uploadingQAandFile } from "./uploadingQAandFile";
import { questionnaireParser } from "./questionnaireParser";
import SignLogin from "../Authentication/Sign-login";
import SignLoginMobile from "../Authentication/SignLoginMobile";
import CategoryCompletedPopup from "./CategoryCompletedPopup";
import questionsData from "./en-questions.json";
import questionsData_ar from "./ar-questions.json";
import questionsFeelingWell from "./feelingWell-ques.json"
import UploadButton from "../UploadButton";
import translations from "../translations";
import "../style/questionnaire.css";
import "@fortawesome/fontawesome-free/css/all.css";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import CryptoJS from "crypto-js";

export function PreFilledQuestions() {
  const {
    errMsg,
    setErrMsg,
    loading,
    signInForVisitor,
    setSignInForVisitor,
    setIsFileUploaded,
    isFileUploaded,
    activeLang,
    setFiles,
    files,
    preFilledQuestions,
    imNotFeelingWell,
    setimNotFeelingWell,
	isGenderChanged,
	setIsGenderChanged
  } = useData();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [questionnaireCompleted, setQuestionnaireCompleted] = useState(false);
  const [animateProgress, setAnimateProgress] = useState(false);
  const [answers, setAnswers] = useState({});
  const [answersInCategory, setAnswersInCategory] = useState({});
  const [otherText, setOtherText] = useState({});
  const [foodText, setFoodText] = useState({});
  const [inputText, setInputText] = useState({});
  const [medicationsText, setMedicationsText] = useState({});
  const [isFemale, setIsFemale] = useState(false);
  const [filledAnswer, setFilledAnswer] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [completedCategory, setCompletedCategory] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const lang = translations[activeLang];
  const navigate = useNavigate();
  const SECRET_KEY = "alhenaie";

  useEffect(() => {
    try {
      if (!preFilledQuestions) {
        return ;
      } else {
        setAnswersInCategory(preFilledQuestions);
        setFilledAnswer(true);
        toast.success("Your previous answers are saved");
      }
    } catch (error) {
      console.error("Parsing error getting prefilled from server", error);
    }
  }, [preFilledQuestions]);

  useEffect(() => {
    const extractedAnswers = {};

    Object.keys(answersInCategory).forEach(section => {
      answersInCategory[section].forEach(question => {
        extractedAnswers[question.label] = question.options;
      });
    });

	  // if (extractedAnswers[0] === "Female" || extractedAnswers[0] === "انثي") {
    //   console.log("in :",extractedAnswers[0]);
		// 	setIsFemale(true);
		// }
    // }
    setAnswers(extractedAnswers);
  }, [filledAnswer, answersInCategory]);


  useEffect(() => {
    if (!isFileUploaded){
      toast.error("Upload your lab test first");
      navigate(-1);
    }
  }, [
    isFileUploaded,
    navigate,
    setFiles,
    setIsFileUploaded,
    setSignInForVisitor,
    imNotFeelingWell
  ]);

  window.onpopstate = () => {
    if (isFileUploaded) {
      toast.success(lang.ProgressMsg);
      setIsFileUploaded(false);
      setFiles([]);
      navigate("/LandingPage");
    }
    if (signInForVisitor) {
      setSignInForVisitor(false);
      navigate("/LandingPage");
    }
    window.onpopstate = null;
  };

  useEffect(() => {
    const getQuestions = () => {
      if (activeLang === "en"){
          setQuestions(flattenQuestions(questionsData));
      }
      else if (activeLang === "ar"){
        setQuestions(flattenQuestions(questionsData_ar));
      }
      else {
        console.error("Unsupported language");
      }
    };
    getQuestions();
  }, [activeLang, imNotFeelingWell]);

  useEffect(() => {
    const filterQuestionsByGender = (questions, isFemale) => {
      if (isFemale) 
		return questions;
      else
        return questions.filter(
          (question) => question.category !== "Women's Health"
        );
    };
    
    setFilteredQuestions(filterQuestionsByGender(questions, isFemale));
  }, [questions, isFemale, isGenderChanged]);

  useEffect(() => {
	if (isGenderChanged)
	{
		setAnswers({});
    setIsGenderChanged(false);
		setIsFemale(!isFemale);
	}
  },[isGenderChanged])

  useEffect(() => {
    setErrMsg("");
    if (currentQuestionIndex > filteredQuestions.length - 1) {
      setQuestionnaireCompleted(true);
    } else {
      setQuestionnaireCompleted(false);
    }
  }, [currentQuestionIndex, filteredQuestions.length, setErrMsg]);

  const handleSubmitAll = (e) => {
    e.preventDefault();
    let error = false;
    let updatedAnswersInCategory = { ...answersInCategory };
  
  
    for (let question of filteredQuestions) {
      let ansr;
      if (question.label === 'health_description')
          ansr = inputText[currentQuestionIndex];
      else 
          ansr = answers[question.label];
      if (questionnaireParser(question, ansr, setErrMsg) === false) {
        setFormLoading(false);
        error = true;
        break;
      }
    }
  
    if (error) return;
  
    for (let question of filteredQuestions) {
      let ansr;
      if (question.label === 'health_description')
          ansr = inputText[currentQuestionIndex];
      else 
          ansr = answers[question.label];
      const category = question.category;
      const { id, label, text, type } = question;
      const newAnswer = {
        id,
        label,
        text,
        type,
        options: [...new Set(ansr)],
      };
  
      if (category) {
        if (!updatedAnswersInCategory[category]) {
          updatedAnswersInCategory[category] = [];
        }
        updatedAnswersInCategory[category] = updatedAnswersInCategory[category].filter(
          (a) => a.id !== id
        );
  
        updatedAnswersInCategory[category].push(newAnswer);
      }
    }
  
    let genderAnswer = updatedAnswersInCategory?.gender?.[0]?.options?.[0];
    if (genderAnswer && (genderAnswer === "Male" || genderAnswer === "ذكر")) {
      updatedAnswersInCategory = Object.keys(updatedAnswersInCategory).reduce((filtered, category) => {
        if (category !== "Women's Health") {
          filtered[category] = updatedAnswersInCategory[category];
        }
        return filtered;
      }, {});
    }
  
    setAnswersInCategory(updatedAnswersInCategory);
    uploadingQAandFile(files, updatedAnswersInCategory, setErrMsg, navigate, setCurrentQuestionIndex);
  };

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      setAnimateProgress(true);
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleOtherChange = (e) => {
    setOtherText((prev) => ({
      ...prev,
      [currentQuestionIndex]: e.target.value,
    }));
  };

  const handleFoodChange = (e) => {
    setFoodText((prev) => ({
      ...prev,
      [currentQuestionIndex]: e.target.value,
    }));
  };

  const handleMedicationsChange = (e) => {
    setMedicationsText((prev) => ({
      ...prev,
      [currentQuestionIndex]: e.target.value,
    }));
  };

  const handleInputText = (e) => {
    setInputText((prev) => ({
      ...prev,
      [currentQuestionIndex]: e.target.value,
    }));
  };

  const specifyOptions = {
    handleInputText,
    inputText: inputText[currentQuestionIndex] || "",
    otherText: otherText[currentQuestionIndex] || "",
    handleOtherChange,
    foodText: foodText[currentQuestionIndex] || "",
    handleFoodChange,
    medicationsText: medicationsText[currentQuestionIndex] || "",
    handleMedicationsChange,
  };

  const handleClose = (event) => {
    event.preventDefault();
    // toast.success(lang.ProgressMsg);
    setIsFileUploaded(false);
    setimNotFeelingWell(false);
    setFiles([]);
    navigate("/LandingPage");
  };

  if (!questions || questions.length === 0) {
    return <LoadingSpinner />;
  }

  const isPreFilled = preFilledQuestions && Object.keys(preFilledQuestions).length > 0;
  const currentCategory = isPreFilled
    ? filteredQuestions[currentQuestionIndex]?.category
    : filteredQuestions[currentQuestionIndex]?.category;
  const categoryIconClass = categoryIcons[currentCategory];

  const translatedTitle = (categoryTitle) => {
    if (activeLang === "ar") {
      switch (categoryTitle) {
        case "gender":
          return "النوع";
        case "General Health Information":
          return "معلومات صحية عامة";
        case "Medical History":
          return "التاريخ الطبي";
        case "Lifestyle and Wellbeing":
          return "نمط الحياة";
        case "Women's Health":
          return "صحة المرأة";
        case "Extended Health History":
          return "التاريخ الطبي (مفصل)";
        default:
          return "";
      }
    } else {
      return categoryTitle;
    }
  };

  return (
    <>
      {signInForVisitor ? (
        !isMobile ? <SignLogin /> : <SignLoginMobile/>
      ) : (
        <>
          {loading ? (
            <LoadingResults />
          ) : (
            <div className="questionnaire-bg">
              <div className="questionnaire-header">
                <h1 className="questionnaire-title">{lang.Questionnaire}</h1>
              </div>
              <div className="container">
                <div className="centered-questionnaire">
                  <Progress
                    currentQuestionIndex={currentQuestionIndex}
                    totalQuestions={filteredQuestions.length}
                    animateProgress={animateProgress}
                    questionnaireCompleted={questionnaireCompleted}
                  />
                  {formLoading && <LoadingSpinner />}
                  {!formLoading && (
                    <form
                      className={`questionnaire-form ${activeLang === "ar" ? "onlyRtl" : ""}`}
                      data-testid="questionnaire"
                      onSubmit={handleSubmitAll}
                    >
                      <i
                        className="fa fa-window-close icon-close-questionnaire"
                        aria-hidden="true"
                        onClick={handleClose}
                      ></i>
                      {errMsg && (
                        <div className="error-message alert alert-danger fade-out">
                          {errMsg}
                        </div>
                      )}
                      <div className="header fade-in">
                      <h2>{lang.ReviewAnswers}</h2>
                      </div>
                      {!isPreFilled && currentQuestionIndex > 0 && !questionnaireCompleted && (
                        <p className="fade-in">
                          {lang.questionsNum} {currentQuestionIndex + 1}{" "}
                          {lang.questionsNumOf} {filteredQuestions.length}
                        </p>
                      )}

                      {completedCategory && (
                        <CategoryCompletedPopup categoryName={completedCategory} />
                      )}
                      {isPreFilled && filteredQuestions.map((ques, index) => (
                          <Question
                            key={ques.label} 
                            question={ques}
                            currentAnswer={answers[ques.label] || []}
                            setAnswers={setAnswers}
                            currentQuestionIndex={ques.label}
                            specifyOptions={specifyOptions}
                          />
                        ))}
                      <br />
                      <div className={`${activeLang === "ar" ? "flex-row-reverse" : "flex-row"}`}>
                        {!isPreFilled && !completedCategory && (
                          <button
                            className="backbtn fade-in"
                            type="button"
                            onClick={handleBack}
                            disabled={isPreFilled}
                          >
                            {lang.Q_backbtn}
                          </button>
                        )}
                        {isPreFilled ? (
                          <button
                            className="nextbtn"
                            type="submit"
                          >
                            {lang.Q_submitbtn}
                          </button>
                        ) : (
                          !questionnaireCompleted && !completedCategory && (
                            <button
                              className="nextbtn fade-in"
                              id="next-btn"
                              type="submit"
                              disabled={questionnaireCompleted}
                              autoFocus
                            >
                              {lang.Q_nextbtn}
                            </button>
                          )
                        )}
                      </div>
                    </form>
                  )}
                  {/* <UploadButton setFiles={setFiles} /> */}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
