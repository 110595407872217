import { Outlet } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import Navbar from "./Navbar";
import { useEffect, useState } from "react";

const Layout = () => {
  const [toastPosition, setToastPosition] = useState("top-right");

  useEffect(() => {
    const updateToastPosition = () => {
      // Set position to bottom-center if screen width is less than 768px (typical mobile breakpoint)
      setToastPosition(window.innerWidth < 768 ? "bottom-center" : "top-center");
    };

    // Initial check
    updateToastPosition();

    // Add event listener to update on resize
    window.addEventListener("resize", updateToastPosition);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", updateToastPosition);
  }, []);

  return (
    <div className="App">
      <Toaster 
        position={toastPosition}
        toastOptions={{
          duration: 8000,
          success: {
            style: {
              backgroundColor: 'green',
              color: 'white',
              zIndex: 9999,
            },
          },
          error: {
            style: {
              backgroundColor: '#e10101',
              color: 'white',
              zIndex: 9999,
            },
          },
          custom: {
            style: {
              backgroundColor: 'yellow',
              color: 'black',
              zIndex: 9999,
            },
          },
        }}
      />
      <Navbar />
      <Outlet />
    </div>
  );
};

export default Layout;
