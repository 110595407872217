import React from 'react';
import { useData } from '../api/store';

export const TextInput = ({ question, currentAnswer, specifyOptions, currentQuestionIndex }) => {
  
  const { inputText , handleInputText , describe} = specifyOptions;
  const { IspreFilled , imNotFeelingWell} = useData();
  
  return (
    <div key={question.id} className='fade-in'>
      <label className='label-q fade-in'>{question.text}</label>
      <input
        type="text" 
        name={`${IspreFilled ? question.label : "answer"}`} 
        defaultValue={`${IspreFilled || imNotFeelingWell ? describe || currentAnswer?.[0] || "" : inputText }`}
        onChange={handleInputText}
        required
      />
    </div>
  );
};
