import {jwtDecode} from 'jwt-decode';
import { useData } from '../api/store';
import toast from 'react-hot-toast';
import axios from 'axios';
import { setAuthTokenCookie } from './setAuthTokenCookie';
const GET_USER_TOKEN = process.env.REACT_APP_BASE_URL + "/api/register_user/";
import translations from '../translations';

export async function handleGoogleSignUp(credentialResponse , navigate) {
    const {activeLang, setLoggedIn , setLoggedInUser , signInForVisitor , setSignInForVisitor, setSignUpActive} = useData.getState();
    const { credential } = credentialResponse;
    const decodedToken = jwtDecode(credential);
    const { email , name} = decodedToken;
    const lang = translations[activeLang];
    console.log(credential);
    console.log(email);
    console.log(name);
    try {
      const response = await axios.post(GET_USER_TOKEN, { 
        vendor: "google",
        email: email,
        access_token: credential,
        name: name,
        consented: "True",
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
      if (response.status === 200 || response.status === 201) {
        setAuthTokenCookie(response.data.token); 
        setLoggedInUser({
          username : response.data.name,
          email : response.data.email,
        })
        toast.success(lang.SignedUp)
        if (signInForVisitor)
        {
          setSignInForVisitor(false);
          return ;
        } else {
            navigate(-1);
            return ;
          }
      }
    } catch (error) {
    if (error.response){
      if (error.response.status === 409){
        toast.error(lang.AlreadySignedIn)
        setSignUpActive(true);
        } else if (error.response.status === 403) {
            toast.error(lang.Forbidden);
        } else if (error.response.status === 400) {
            toast.error(lang.BadRequest);
        } else if (error.response.status === 500){
            toast.error(lang.ServerError);
        }
    }else{
      toast.error(lang.NetworkError);
    }
      return;
    }
}
