import React, { useState, useEffect, useRef } from "react";
import { useData } from "../api/store";
import { flattenQuestions, categoryIcons } from "./helpers";
import LoadingResults from "./LoadingResults";
import LoadingSpinner from "./FormLoading";
import { uploadingQAandFile } from "./uploadingQAandFile";
import { uploadingFeelingWell } from "./UploadingFeelingWell";
import { questionnaireParser } from "./questionnaireParser";
import SignLogin from "../Authentication/Sign-login";
import SignLoginMobile from "../Authentication/SignLoginMobile";
import questionsData from "./en-questions.json";
import questionsData_ar from "./ar-questions.json";
import translations from "../translations";
import "../style/questionnaire.css";
import "@fortawesome/fontawesome-free/css/all.css";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

export function UnifiedQuestionnaire() {
  const {
    errMsg,
    setErrMsg,
    loading,
    signInForVisitor,
    setSignInForVisitor,
    setIsFileUploaded,
    isFileUploaded,
    activeLang,
    setFiles,
    files,
    preFilledQuestions,
    imNotFeelingWell,
    setimNotFeelingWell,
    setIsGenderChanged,
    IspreFilled,
  } = useData();
  
  const [questions, setQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [formLoading, setFormLoading] = useState(false);
  const [isFemale, setIsFemale] = useState(false);
  const [answers, setAnswers] = useState({});
  const [answersInCategory, setAnswersInCategory] = useState({});
  const [otherText, setOtherText] = useState({});
  const [foodText, setFoodText] = useState({});
  const [inputText, setInputText] = useState({});
  const [medicationsText, setMedicationsText] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [oldChoice, setOldChoice] = useState({});
  const [showHealthDescriptionFirst, setShowHealthDescriptionFirst] = useState(false);
  const [healthDescription, setHealthDescription] = useState("");
  const [healthDescriptionSubmitted, setHealthDescriptionSubmitted] = useState(false);
  const [previousLang, setPreviousLang] = useState(activeLang);
  const invalidQuestionRef = useRef(null);
  const toastShownRef = useRef(false);
  
  const lang = translations[activeLang];
  const navigate = useNavigate();

  // Function to map answers between languages
  const mapAnswersBetweenLanguages = (currentAnswers, fromLang, toLang) => {
    if (Object.keys(currentAnswers).length === 0) return {};
    
    // Create map of questions by label for both languages
    const getQuestionsMap = (questionsJson) => {
      const map = {};
      Object.keys(questionsJson).forEach(category => {
        questionsJson[category].forEach(q => {
          map[q.label] = q;
        });
      });
      return map;
    };
    
    const fromLangQuestions = getQuestionsMap(fromLang === 'en' ? questionsData : questionsData_ar);
    const toLangQuestions = getQuestionsMap(toLang === 'en' ? questionsData : questionsData_ar);
    
    const translatedAnswers = { ...currentAnswers };
    
    Object.keys(currentAnswers).forEach(label => {
      // Skip if the current question doesn't have answers or doesn't exist in both languages
      if (!currentAnswers[label] || currentAnswers[label].length === 0 ||
          !fromLangQuestions[label] || !toLangQuestions[label]) {
        return;
      }
      
      const fromOptions = fromLangQuestions[label].options;
      const toOptions = toLangQuestions[label].options;
      
      // Skip if options don't match in length (safety check)
      if (fromOptions.length !== toOptions.length) {
        console.warn(`Options length mismatch for ${label}. Can't translate accurately.`);
        return;
      }
      
      // For questions with options (single choice, multiple choice)
      if (fromLangQuestions[label].type === 'single-choice' || 
          fromLangQuestions[label].type === 'multiple-choice') {
        
        // Map each selected option to its equivalent in the target language
        translatedAnswers[label] = currentAnswers[label].map(answer => {
          // Special case for "Other" options - preserve as is
          if (answer.includes('Other') || answer.includes('أخرى') ||
              answer.includes('Food') || answer.includes('الطعام') ||
              answer.includes('Medications') || answer.includes('الأدوية')) {
            
            // Find the equivalent special option in the target language
            if ((answer.includes('Other') || answer.includes('أخرى'))) {
              return toOptions.find(opt => opt.includes('Other') || opt.includes('أخرى')) || answer;
            }
            else if ((answer.includes('Food') || answer.includes('الطعام'))) {
              return toOptions.find(opt => opt.includes('Food') || opt.includes('الطعام')) || answer;
            }
            else if ((answer.includes('Medications') || answer.includes('الأدوية'))) {
              return toOptions.find(opt => opt.includes('Medications') || opt.includes('الأدوية')) || answer;
            }
            return answer;
          }
          
          // For regular options, map by index
          const index = fromOptions.indexOf(answer);
          if (index !== -1 && index < toOptions.length) {
            return toOptions[index];
          }
          
          // If we can't find a match, return the original answer
          return answer;
        });
      }
    });
    
    return translatedAnswers;
  };

  // Function to translate the answers in category structure
  const translateAnswersInCategory = (currentAnswersInCategory, fromLang, toLang) => {
    if (!currentAnswersInCategory || Object.keys(currentAnswersInCategory).length === 0) {
      return {};
    }
    
    // Create flattened collections of questions for mapping
    const fromQuestions = flattenQuestions(fromLang === 'en' ? questionsData : questionsData_ar);
    const toQuestions = flattenQuestions(toLang === 'en' ? questionsData : questionsData_ar);
    
    // Create maps for quick lookup
    const fromQMap = fromQuestions.reduce((map, q) => {
      map[q.label] = q;
      return map;
    }, {});
    
    const toQMap = toQuestions.reduce((map, q) => {
      map[q.label] = q;
      return map;
    }, {});
    
    // Clone the current structure to avoid mutations
    const translatedCategories = JSON.parse(JSON.stringify(currentAnswersInCategory));
    
    // Go through each category and translate answers
    Object.keys(translatedCategories).forEach(category => {
      // Find matching category in target language (might have a different name)
      translatedCategories[category].forEach(question => {
        const { label, type, options } = question;
        
        // Skip if question type doesn't have options to translate
        if (type === 'text' || type === 'number' || !options || options.length === 0) {
          return;
        }
        
        // Get source and target question
        const sourceQ = fromQMap[label];
        const targetQ = toQMap[label];
        
        // Skip if we can't find the questions in both languages
        if (!sourceQ || !targetQ) {
          return;
        }
        
        // Translate each option
        const translatedOptions = [];
        
        options.forEach(opt => {
          // Handle special options (Other, Food, Medications)
          if ((opt.includes('Other') || opt.includes('أخرى'))) {
            const targetOption = targetQ.options.find(o => 
              o.includes('Other') || o.includes('أخرى'));
            if (targetOption) {
              translatedOptions.push(targetOption);
            } else {
              translatedOptions.push(opt); // keep original
            }
          }
          else if ((opt.includes('Food') || opt.includes('الطعام'))) {
            const targetOption = targetQ.options.find(o => 
              o.includes('Food') || o.includes('الطعام'));
            if (targetOption) {
              translatedOptions.push(targetOption);
            } else {
              translatedOptions.push(opt);
            }
          }
          else if ((opt.includes('Medications') || opt.includes('الأدوية'))) {
            const targetOption = targetQ.options.find(o => 
              o.includes('Medications') || o.includes('الأدوية'));
            if (targetOption) {
              translatedOptions.push(targetOption);
            } else {
              translatedOptions.push(opt);
            }
          }
          else {
            // For regular options, find by index in the source and get same index in target
            const index = sourceQ.options.indexOf(opt);
            if (index !== -1 && index < targetQ.options.length) {
              translatedOptions.push(targetQ.options[index]);
            } else {
              translatedOptions.push(opt); // keep original if no match
            }
          }
        });
        
        // Update options with translated ones
        question.options = translatedOptions;
      });
    });
    
    return translatedCategories;
  };

  // Initialize questions from JSON data
  useEffect(() => {
    const getQuestions = () => {
      if (activeLang === "en") {
        setQuestions(flattenQuestions(questionsData));
      } else if (activeLang === "ar") {
        setQuestions(flattenQuestions(questionsData_ar));
      } else {
        console.error("Unsupported language");
      }
    };
    
    getQuestions();
    
    // If there are existing answers and language has changed, translate them
    if (previousLang !== activeLang && Object.keys(answers).length > 0) {
      // Translate the existing answers to the new language
      const translatedAnswers = mapAnswersBetweenLanguages(answers, previousLang, activeLang);
      setAnswers(translatedAnswers);
      
      // Translate answers in category structure if any
      if (Object.keys(answersInCategory).length > 0) {
        const translatedCategories = translateAnswersInCategory(answersInCategory, previousLang, activeLang);
        setAnswersInCategory(translatedCategories);
      }
      
      // Get flattened questions for both languages for mapping
      const fromQuestions = flattenQuestions(previousLang === 'en' ? questionsData : questionsData_ar);
      const toQuestions = flattenQuestions(activeLang === 'en' ? questionsData : questionsData_ar);
      
      // Map questions by label
      const fromQMap = fromQuestions.reduce((map, q) => {
        map[q.label] = q;
        return map;
      }, {});
      
      const toQMap = toQuestions.reduce((map, q) => {
        map[q.label] = q;
        return map;
      }, {});
      
      // Preserve all text inputs - these don't need translation, just association with the new language questions
      if (Object.keys(inputText).length > 0) {
        // Collect all text-type questions in the target language
        const textQuestions = toQuestions.filter(q => q.type === "text");
        
        // For each existing input text, ensure it's associated with the right question in the new language
        Object.keys(inputText).forEach(label => {
          // Check if this question exists in the new language
          const targetQuestion = textQuestions.find(q => q.label === label);
          if (targetQuestion) {
            // Preserve the text - no need to translate it
            const text = inputText[label];
            
            // Ensure it's in the answers
            translatedAnswers[label] = [text];
            
            // Ensure it's in answersInCategory
            if (translatedCategories && Object.keys(translatedCategories).length > 0) {
              const category = targetQuestion.category;
              
              if (translatedCategories[category]) {
                const existingQ = translatedCategories[category].find(q => q.label === label);
                
                if (existingQ) {
                  existingQ.options = [text];
                } else {
                  // Add it if not found
                  translatedCategories[category].push({
                    id: targetQuestion.id,
                    label: targetQuestion.label,
                    text: targetQuestion.text,
                    type: targetQuestion.type,
                    options: [text]
                  });
                }
              }
            }
          }
        });
        
        // Update answers in category
        setAnswersInCategory(translatedCategories);
      }
      
      // Make sure health description is preserved
      if (healthDescription && healthDescriptionSubmitted) {
        // Find the health description question in the current language
        const healthDescQ = toQuestions.find(q => q.label === "health_description");
        
        if (healthDescQ) {
          // Ensure health description is in the answers
          translatedAnswers[healthDescQ.label] = [healthDescription];
          
          // Ensure it's also in the inputText
          setInputText(prev => ({
            ...prev,
            [healthDescQ.label]: healthDescription
          }));
          
          // Ensure it's in answersInCategory
          if (translatedCategories && Object.keys(translatedCategories).length > 0) {
            const category = healthDescQ.category;
            
            if (translatedCategories[category]) {
              const existingQ = translatedCategories[category].find(q => q.label === "health_description");
              
              if (existingQ) {
                existingQ.options = [healthDescription];
              } else {
                // Add it if not found
                translatedCategories[category].push({
                  id: healthDescQ.id,
                  label: healthDescQ.label,
                  text: healthDescQ.text,
                  type: healthDescQ.type,
                  options: [healthDescription]
                });
              }
              
              // Update the answers in category
              setAnswersInCategory(translatedCategories);
            }
          }
        }
      }
      
      // For custom text inputs, we don't translate them but ensure they're 
      // properly associated with the right option
      ['otherText', 'foodText', 'medicationsText'].forEach(textType => {
        let stateGetter;
        let stateSetter;
        
        // Get the appropriate state getter and setter
        if (textType === 'otherText') {
          stateGetter = otherText;
          stateSetter = setOtherText;
        } else if (textType === 'foodText') {
          stateGetter = foodText;
          stateSetter = setFoodText;
        } else {
          stateGetter = medicationsText;
          stateSetter = setMedicationsText;
        }
        
        if (Object.keys(stateGetter).length > 0) {
          // Preserve all the text values but ensure they're associated with the right question
          Object.keys(stateGetter).forEach(label => {
            // Skip if we can't find the question in either language
            if (!fromQMap[label] || !toQMap[label]) return;
            
            // Get the custom text
            const textValue = stateGetter[label];
            
            // The label stays the same across languages, so no mapping needed
            // Just ensure this option is selected in the translated answers
            
            // Look for the appropriate special option in the current language
            const optionText = textType === 'otherText' ? 'Other' : 
                               textType === 'foodText' ? 'Food' : 'Medications';
            const optionTextAr = textType === 'otherText' ? 'أخرى' : 
                                textType === 'foodText' ? 'الطعام' : 'الأدوية';
                                
            const specialOption = toQMap[label].options.find(opt => 
              opt.includes(optionText) || opt.includes(optionTextAr));
              
            // Ensure this option is selected in the translated answers
            if (specialOption && translatedAnswers[label]) {
              if (!translatedAnswers[label].some(ans => 
                  ans.includes(optionText) || ans.includes(optionTextAr))) {
                  
                // For single-choice, replace the answer
                if (toQMap[label].type === 'single-choice') {
                  translatedAnswers[label] = [specialOption];
                } 
                // For multiple-choice, add to the existing answers
                else {
                  translatedAnswers[label].push(specialOption);
                }
              }
            }
          });
        }
      });
      
      // Update the answers after adjusting them
      setAnswers(translatedAnswers);
      
      // Show toast message about translation
      toast.success(activeLang === 'ar' 
        ? "تم ترجمة إجاباتك إلى العربية"
        : "Your answers have been translated to English");
    }
    
    // Update previous language
    setPreviousLang(activeLang);
  }, [activeLang, imNotFeelingWell, answers, answersInCategory, previousLang, otherText, foodText, medicationsText, healthDescription, healthDescriptionSubmitted, inputText]);

  // Filter questions by gender
  useEffect(() => {
    const filterQuestionsByGender = (questions, isFemale) => {
      if (isFemale) 
        return questions;
      else
        return questions.filter(
          (question) => question.category !== "Women's Health"
        );
    };
    
    setFilteredQuestions(filterQuestionsByGender(questions, isFemale));
  }, [questions, isFemale]);

  // Check if lab test is uploaded (only if not in "I'm not feeling well" mode)
  useEffect(() => {
    // Skip this check entirely if URL already has a query parameter
    // This prevents unwanted redirects when form submissions add '?' to URL
    if (window.location.search) {
      return;
    }
    
    // Check for file upload ONLY if we're not in "I'm not feeling well" mode
    if (!imNotFeelingWell && !isFileUploaded) {
      toast.error("Upload your lab test first");
      navigate("/LandingPage", { replace: true });
      return;
    }
    
    // Set the initial state for health description first screen
    if (imNotFeelingWell && !healthDescriptionSubmitted) {
      setShowHealthDescriptionFirst(true);
    }
  }, [
    isFileUploaded,
    navigate,
    imNotFeelingWell,
    healthDescriptionSubmitted
  ]);

  // Handle browser back button
  useEffect(() => {
    const handlePopState = () => {
      if (isFileUploaded || imNotFeelingWell) {
        setIsFileUploaded(false);
        setimNotFeelingWell(false);
        setFiles([]);
        navigate("/LandingPage");
      }
      if (signInForVisitor) {
        setSignInForVisitor(false);
        navigate("/LandingPage");
      }
    };

    window.onpopstate = handlePopState;
    return () => {
      window.onpopstate = null;
    };
  }, [isFileUploaded, imNotFeelingWell, signInForVisitor, navigate, setFiles, setIsFileUploaded, setSignInForVisitor, setimNotFeelingWell]);

  // Function to detect the source language of the prefilled data
  const detectSourceLanguage = (data) => {
    if (!data || Object.keys(data).length === 0) return 'unknown';
    
    let englishPatterns = 0;
    let arabicPatterns = 0;
    let totalOptions = 0;
    
    // Count English and Arabic patterns across all questions
    Object.keys(data).forEach(section => {
      data[section].forEach(q => {
        if (q.options && q.options.length > 0) {
          q.options.forEach(opt => {
            totalOptions++;
            
            // Check for common English keywords
            if (/\b(Male|Female|None|Other|Food|Medications|the|and|or|of)\b/.test(opt)) {
              englishPatterns++;
            }
            
            // Check for Arabic characters
            if (/[\u0600-\u06FF]/.test(opt)) {
              arabicPatterns++;
            }
          });
        }
      });
    });
    
    console.log(`Detected ${englishPatterns} English patterns and ${arabicPatterns} Arabic patterns out of ${totalOptions} options`);
    
    // Determine language based on majority patterns
    if (englishPatterns > arabicPatterns && englishPatterns > totalOptions * 0.3) {
      return 'en';
    } else if (arabicPatterns > englishPatterns && arabicPatterns > totalOptions * 0.3) {
      return 'ar';
    } else {
      return 'unknown';
    }
  };

  // Extract and process prefilled answers
  const extractPrefilledData = (preFilledQuestions) => {
    const extractedAnswers = {};
    const extractedTextInputs = {};
    const extractedOtherText = {};
    const extractedFoodText = {};
    const extractedMedicationsText = {};
    let shouldSetFemale = false;

    if (!preFilledQuestions || Object.keys(preFilledQuestions).length === 0) {
      return { 
        extractedAnswers, 
        extractedTextInputs, 
        extractedOtherText, 
        extractedFoodText, 
        extractedMedicationsText,
        shouldSetFemale
      };
    }
    
    console.log("Processing prefilled data:", JSON.stringify(preFilledQuestions));
    
    // Determine the source language of the prefilled data using more reliable detection
    const sourceLanguage = detectSourceLanguage(preFilledQuestions);
    console.log(`Detected source language of prefilled data: ${sourceLanguage}`);
    console.log(`Current UI language: ${activeLang}`);
    
    // If languages don't match, we need to do translation
    const needsTranslation = sourceLanguage !== 'unknown' && sourceLanguage !== activeLang;
    
    // First, load the real question options from our local data
    const allQuestionOptions = {};
    flattenQuestions(activeLang === "en" ? questionsData : questionsData_ar).forEach(q => {
      allQuestionOptions[q.label] = q.options;
    });
    
    // Create maps for translation if needed
    let sourceQMap = {};
    let targetQMap = {};
    
    if (needsTranslation) {
      console.log("Translating prefilled answers to match current UI language");
      
      // Get flattened questions for both languages
      const sourceQuestions = flattenQuestions(sourceLanguage === 'en' ? questionsData : questionsData_ar);
      const targetQuestions = flattenQuestions(activeLang === 'en' ? questionsData : questionsData_ar);
      
      // Create maps for quick lookup
      sourceQMap = sourceQuestions.reduce((map, q) => {
        map[q.label] = q;
        return map;
      }, {});
      
      targetQMap = targetQuestions.reduce((map, q) => {
        map[q.label] = q;
        return map;
      }, {});
    }
    
    // Function to translate an option by index
    const translateOption = (label, option) => {
      if (!needsTranslation) return option;
      
      // Get source and target question
      const sourceQ = sourceQMap[label];
      const targetQ = targetQMap[label];
      
      // Skip if we can't find the questions in both languages
      if (!sourceQ || !targetQ) return option;
      
      // Handle special options
      if (option.includes('Other') || option.includes('أخرى')) {
        return targetQ.options.find(o => o.includes('Other') || o.includes('أخرى')) || option;
      }
      if (option.includes('Food') || option.includes('الطعام')) {
        return targetQ.options.find(o => o.includes('Food') || o.includes('الطعام')) || option;
      }
      if (option.includes('Medications') || option.includes('الأدوية')) {
        return targetQ.options.find(o => o.includes('Medications') || o.includes('الأدوية')) || option;
      }
      
      // For regular options, find by index
      const index = sourceQ.options.indexOf(option);
      if (index !== -1 && index < targetQ.options.length) {
        return targetQ.options[index];
      }
      
      return option; // Keep original if no match
    };
    
    Object.keys(preFilledQuestions).forEach(section => {
      preFilledQuestions[section].forEach(question => {
        const { label, type, options, text } = question;
        
        console.log(`Processing question: ${label} (${type}) - ${text}`, options);
        
        // Handle gender question specially
        if (label === 'gender' && options.length > 0) {
          const genderOption = options[0];
          if (genderOption === "Female" || genderOption === "انثي") {
            shouldSetFemale = true;
          }
        }
        
        // Handle text inputs
        if (type === "text") {
          extractedTextInputs[label] = options[0] || " ";
          extractedAnswers[label] = options;
          return;
        }
        
        // Get standard options from our question bank to compare against
        const standardOptions = allQuestionOptions[label] || [];
        console.log(`Standard options for ${label}:`, standardOptions);
        
        // For single-choice questions, check if the value is a custom entry (not in standard options)
        if (type === "single-choice" && options.length > 0) {
          const value = options[0];
          
          // If we need translation, translate the option first
          const translatedValue = needsTranslation ? translateOption(label, value) : value;
          
          // If the selected value is not among standard options (and not 'Other' itself),
          // it's likely a custom "Other" entry
          if (!standardOptions.includes(translatedValue) && 
              !translatedValue.includes('Other') && !translatedValue.includes('أخرى') &&
              !translatedValue.includes('Food') && !translatedValue.includes('الطعام') &&
              !translatedValue.includes('Medications') && !translatedValue.includes('الأدوية')) {
            
            // Find the "Other" option in standard options
            const otherOption = standardOptions.find(opt => 
              opt.includes('Other') || opt.includes('أخرى'));
              
            // If there's an "Other" option available, mark it as selected and store the custom text
            if (otherOption) {
              extractedAnswers[label] = [otherOption];
              extractedOtherText[label] = value;
              console.log(`Found custom 'Other' text for ${label}: ${value}`);
            } else {
              // Otherwise, just use the value as is
              extractedAnswers[label] = [translatedValue];
            }
          } 
          // Similarly check for Food and Medications
          else if (!standardOptions.includes(translatedValue) && 
              (translatedValue.includes('Food') || translatedValue.includes('الطعام'))) {
            const foodOption = standardOptions.find(opt => 
              opt.includes('Food') || opt.includes('الطعام'));
            if (foodOption) {
              extractedAnswers[label] = [foodOption];
              extractedFoodText[label] = value;
            } else {
              extractedAnswers[label] = [translatedValue];
            }
          }
          else if (!standardOptions.includes(translatedValue) && 
              (translatedValue.includes('Medications') || translatedValue.includes('الأدوية'))) {
            const medOption = standardOptions.find(opt => 
              opt.includes('Medications') || opt.includes('الأدوية'));
            if (medOption) {
              extractedAnswers[label] = [medOption];
              extractedMedicationsText[label] = value;
            } else {
              extractedAnswers[label] = [translatedValue];
            }
          }
          else {
            // For regular selections, translate if needed
            if (needsTranslation) {
              extractedAnswers[label] = [translatedValue];
            } else {
              extractedAnswers[label] = [value];
            }
          }
        }
        // For multiple-choice, we need to check each selection
        else if (type === "multiple-choice") {
          // Filter standard and custom entries
          const standardSelections = [];
          
          console.log(`Processing multiple-choice for ${label} with options:`, options);
          
          // First pass - identify standard options vs custom entries
          const standardOptionMatches = [];
          const customEntries = [];
          
          options.forEach(opt => {
            // Translate the option if needed
            const translatedOpt = needsTranslation ? translateOption(label, opt) : opt;
            
            if (standardOptions.includes(translatedOpt) || 
                translatedOpt.includes('Other') || translatedOpt.includes('أخرى') ||
                translatedOpt.includes('Food') || translatedOpt.includes('الطعام') ||
                translatedOpt.includes('Medications') || translatedOpt.includes('الأدوية')) {
              standardOptionMatches.push(translatedOpt);
            } else {
              customEntries.push(translatedOpt);
              console.log(`Found custom entry for ${label}: ${translatedOpt}`);
            }
          });
          
          // Add all standard options to our selections
          standardSelections.push(...standardOptionMatches);
          
          console.log(`Standard selections for ${label}:`, standardSelections);
          console.log(`Custom entries for ${label}:`, customEntries);
          
          // Simplified logic: Look for option labels and match with text more directly
          // Set all special options to selected if we have custom entries for them
          
          // For each custom entry, try to match it with one of the special options
          customEntries.forEach(customText => {
            // Check for standard options that match our special types
            const otherOption = standardOptions.find(opt => 
              opt.includes('Other') || opt.includes('أخرى'));
            
            const foodOption = standardOptions.find(opt => 
              opt.includes('Food') || opt.includes('الطعام'));
              
            const medOption = standardOptions.find(opt => 
              opt.includes('Medications') || opt.includes('الأدوية'));
            
            // Check if standard options are selected
            const hasOtherOption = standardSelections.some(opt => 
              opt.includes('Other') || opt.includes('أخرى'));
              
            const hasFoodOption = standardSelections.some(opt => 
              opt.includes('Food') || opt.includes('الطعام'));
              
            const hasMedicationsOption = standardSelections.some(opt => 
              opt.includes('Medications') || opt.includes('الأدوية'));
            
            // Try to find the best match for this custom entry
            // If the special option is already selected, use it
            if (hasOtherOption && !extractedOtherText[label]) {
              extractedOtherText[label] = customText;
              console.log(`Assigned "${customText}" to Other for ${label}`);
            } 
            else if (hasFoodOption && !extractedFoodText[label]) {
              extractedFoodText[label] = customText;
              console.log(`Assigned "${customText}" to Food for ${label}`);
            }
            else if (hasMedicationsOption && !extractedMedicationsText[label]) {
              extractedMedicationsText[label] = customText;
              console.log(`Assigned "${customText}" to Medications for ${label}`);
            }
            // If no special option is selected but they exist in the question,
            // select the first matching one and assign the text
            else if (otherOption && !hasOtherOption && !extractedOtherText[label]) {
              standardSelections.push(otherOption);
              extractedOtherText[label] = customText;
              console.log(`Selected Other option and assigned "${customText}" for ${label}`);
            }
            else if (foodOption && !hasFoodOption && !extractedFoodText[label]) {
              standardSelections.push(foodOption);
              extractedFoodText[label] = customText;
              console.log(`Selected Food option and assigned "${customText}" for ${label}`);
            }
            else if (medOption && !hasMedicationsOption && !extractedMedicationsText[label]) {
              standardSelections.push(medOption);
              extractedMedicationsText[label] = customText;
              console.log(`Selected Medications option and assigned "${customText}" for ${label}`);
            }
            else {
              // If we can't assign it to a special option, just add it as a regular selection
              console.log(`Could not match "${customText}" to any special option for ${label}`);
            }
          });
          
          extractedAnswers[label] = standardSelections;
          console.log(`Final selections for ${label}:`, standardSelections);
          
          if (extractedOtherText[label]) {
            console.log(`Other text for ${label}:`, extractedOtherText[label]);
          }
          if (extractedFoodText[label]) {
            console.log(`Food text for ${label}:`, extractedFoodText[label]);
          }
          if (extractedMedicationsText[label]) {
            console.log(`Medications text for ${label}:`, extractedMedicationsText[label]);
          }
        }
        else {
          // For other question types, use the options as is
          extractedAnswers[label] = options;
        }
      });
    });
    
    console.log("Extracted answers:", extractedAnswers);
    console.log("Extracted otherText:", extractedOtherText);
    console.log("Extracted foodText:", extractedFoodText);
    console.log("Extracted medicationsText:", extractedMedicationsText);
    
    return { 
      extractedAnswers, 
      extractedTextInputs, 
      extractedOtherText, 
      extractedFoodText, 
      extractedMedicationsText,
      shouldSetFemale
    };
  };

  // Load prefilled answers if available
  useEffect(() => {
    if (preFilledQuestions && Object.keys(preFilledQuestions).length > 0) {
      setAnswersInCategory(preFilledQuestions);
      
      console.log(JSON.stringify(preFilledQuestions));
      // Extract answers from categories for easier access in form
      const { 
        extractedAnswers, 
        extractedTextInputs, 
        extractedOtherText, 
        extractedFoodText, 
        extractedMedicationsText,
        shouldSetFemale
      } = extractPrefilledData(preFilledQuestions);
      
      // Set isFemale based on the returned value, not directly in the extract function
      if (shouldSetFemale) {
        setIsFemale(true);
      }
      
      setAnswers(extractedAnswers);
      setInputText(extractedTextInputs);
      setOtherText(extractedOtherText);
      setFoodText(extractedFoodText);
      setMedicationsText(extractedMedicationsText);
      
      // Detect if answers were translated from another language with our improved detector
      const sourceLanguage = detectSourceLanguage(preFilledQuestions);
      
      // Show appropriate toast notifications
      if (!toastShownRef.current) {
        // Base success message
        toast.success(activeLang === 'ar' 
          ? "تم تحميل إجاباتك السابقة" 
          : "Your previous answers are loaded");
        
        // If we detected a language difference, show an additional notification
        if (sourceLanguage !== 'unknown' && sourceLanguage !== activeLang) {
          setTimeout(() => {
            if (activeLang === 'ar' && sourceLanguage === 'en') {
              toast.success("تم ترجمة إجاباتك من الإنجليزية إلى العربية");
            } else if (activeLang === 'en' && sourceLanguage === 'ar') {
              toast.success("Your answers have been translated from Arabic to English");
            }
          }, 1000); // Delay second toast slightly
        }
        
        toastShownRef.current = true;
      }
    } else if (filteredQuestions.length > 0) {
      // Initialize default answers for new users
      const defaultAnswers = {};
      let shouldBeFemale = false;
      
      filteredQuestions.forEach(question => {
        if (question.type === "single-choice") {
          defaultAnswers[question.label] = [question.options[0]];
          
          // Check gender but don't set state directly
          if (question.label === 'gender') {
            const selectedGender = question.options[0];
            shouldBeFemale = selectedGender === "Female" || selectedGender === "انثي";
          }
        } else if (question.type === "multiple-choice") {
          const noneOption = question.options.findIndex(option => 
            option.includes("None of the above") || option.includes("None") || 
            option.includes('لا شيء مما ذكر أعلاه') || option.includes("لا شيء") || 
            option.includes("لا أستخدم"));
          
          if (noneOption !== -1) {
            defaultAnswers[question.label] = [question.options[noneOption]];
          } else {
            defaultAnswers[question.label] = [];
          }
        } else {
          defaultAnswers[question.label] = [];
        }
      });
      
      setAnswers(defaultAnswers);
      
      // Set gender after we've analyzed all questions
      if (shouldBeFemale !== isFemale) {
        setIsFemale(shouldBeFemale);
      }
    }
  }, [preFilledQuestions, filteredQuestions.length, isFemale, activeLang]);

  // Handle gender change effect - separate from the main prefill effect
  useEffect(() => {
    // Check if gender answer has changed
    const genderAnswers = answers.gender;
    if (genderAnswers?.length > 0) {
      const currentGenderChoice = genderAnswers[0];
      const previousChoice = oldChoice.gender;
      
      if (previousChoice !== undefined && previousChoice !== currentGenderChoice) {
        setIsGenderChanged(currentGenderChoice);
      }
      
      setOldChoice(prev => ({...prev, gender: currentGenderChoice}));
    }
  }, [answers.gender, oldChoice.gender, setIsGenderChanged]);

  // Handle health description submission
  const handleHealthDescriptionSubmit = (e) => {
    if (e) e.preventDefault();
    
    if (!healthDescription.trim()) {
      toast.error("Please describe your health condition");
      return;
    }
    
    // Save the health description to the answers state
    const healthDescriptionQuestion = filteredQuestions.find(q => q.label === "health_description");
    
    if (healthDescriptionQuestion) {
      // Store the health description in inputText state for this question
      setInputText(prev => ({
        ...prev,
        [healthDescriptionQuestion.label]: healthDescription
      }));
      
      // Store the description in answers state
      setAnswers(prev => ({
        ...prev,
        [healthDescriptionQuestion.label]: [healthDescription]
      }));
      
      // Add to answersInCategory
      const category = healthDescriptionQuestion.category;
      const { id, label, text, type } = healthDescriptionQuestion;
      
      setAnswersInCategory(prev => {
        const updatedAnswers = { ...prev };
        
        if (!updatedAnswers[category]) {
          updatedAnswers[category] = [];
        }
        
        updatedAnswers[category] = updatedAnswers[category].filter(
          (a) => a.id !== id
        );
        
        updatedAnswers[category].push({
          id,
          label,
          text,
          type,
          options: [healthDescription]
        });
        
        return updatedAnswers;
      });
    }
    
    setHealthDescriptionSubmitted(true);
    setShowHealthDescriptionFirst(false);
  };

  // Ensure multiple choice always has at least one selection
  const ensureValidSelections = () => {
    let updatedAnswers = { ...answers };
    let hasChanges = false;
    
    filteredQuestions.forEach(question => {
      if (question.type === "multiple-choice" || question.type === "single-choice") {
        const currentAnswers = updatedAnswers[question.label] || [];
        
        if (currentAnswers.length === 0) {
          // Find "None" option
          const noneOption = question.options.find(option => 
            option.includes("None of the above") || option.includes("None") || 
            option.includes('لا شيء مما ذكر أعلاه') || option.includes("لا شيء") || 
            option.includes("لا أستخدم"));
          
          if (noneOption) {
            updatedAnswers[question.label] = [noneOption];
            hasChanges = true;
          } else if (question.type === "single-choice") {
            // For single-choice, default to first option if no "None" option exists
            updatedAnswers[question.label] = [question.options[0]];
            hasChanges = true;
          }
        }
      }
    });
    
    if (hasChanges) {
      setAnswers(updatedAnswers);
    }
    
    return updatedAnswers;
  };

  // Validate form data before submission
  const validateFormData = () => {
    // Ensure text inputs have at least a space if empty to pass validation
    let updatedInputText = { ...inputText };
    filteredQuestions.forEach(question => {
      if (question.type === "text" && (!updatedInputText[question.label] || updatedInputText[question.label].trim() === "")) {
        updatedInputText[question.label] = " "; // Use a space to prevent empty validation error
      }
    });
    setInputText(updatedInputText);
    
    // Ensure all multiple and single choice questions have at least one selection
    const updatedAnswers = ensureValidSelections();
    
    // Check for "Other" options that need text input
    for (let question of filteredQuestions) {
      if (question.type === "multiple-choice" || question.type === "single-choice") {
        const currentAnswer = updatedAnswers[question.label] || [];
        
        // Check if any option is selected
        if (currentAnswer.length === 0) {
          setErrMsg(activeLang === 'ar' 
            ? `يرجى اختيار خيار واحد على الأقل في سؤال: ${question.text}`
            : `Please select at least one option in question: ${question.text}`);
          
          // Focus on the invalid question
          invalidQuestionRef.current = question.id;
          return false;
        }
        
        // Check if "Other" is the only selected option but no text is provided
        if (currentAnswer.length === 1 && 
            currentAnswer.some(ans => ans.includes('Other') || ans.includes('أخرى')) && 
            (!otherText[question.label] || otherText[question.label].trim() === "")) {
          setErrMsg(activeLang === 'ar' 
            ? `يرجى تحديد النص لـ "أخرى" في سؤال: ${question.text}`
            : `Please specify text for "Other" in question: ${question.text}`);
          invalidQuestionRef.current = question.id;
          return false;
        }
        
        // Check if "Food" is the only selected option but no text is provided
        if (currentAnswer.length === 1 && 
            currentAnswer.some(ans => ans.includes('Food') || ans.includes('الطعام')) && 
            (!foodText[question.label] || foodText[question.label].trim() === "")) {
          setErrMsg(activeLang === 'ar' 
            ? `يرجى تحديد الحساسية الغذائية في سؤال: ${question.text}`
            : `Please specify Food Allergies in question: ${question.text}`);
          invalidQuestionRef.current = question.id;
          return false;
        }
        
        // Check if "Medications" is the only selected option but no text is provided
        if (currentAnswer.length === 1 && 
            currentAnswer.some(ans => ans.includes('Medications') || ans.includes('الأدوية')) && 
            (!medicationsText[question.label] || medicationsText[question.label].trim() === "")) {
          setErrMsg(activeLang === 'ar' 
            ? `يرجى تحديد الأدوية في سؤال: ${question.text}`
            : `Please specify Medications in question: ${question.text}`);
          invalidQuestionRef.current = question.id;
          return false;
        }
      }
    }
    
    // Validate all answers with questionnaireParser
    for (let question of filteredQuestions) {
      let currentAnswer;
      if (question.type === "text") {
        currentAnswer = updatedInputText[question.label] || " ";
      } else {
        currentAnswer = updatedAnswers[question.label] || [];
      }
      
      if (questionnaireParser(question, currentAnswer, setErrMsg) === false) {
        invalidQuestionRef.current = question.id;
        return false;
      }
    }
    
    invalidQuestionRef.current = null;
    return true;
  };

  // Build answers with special input text
  const buildAnswersWithSpecialInputs = () => {
    let updatedAnswersInCategory = { ...answersInCategory };
    
    for (let question of filteredQuestions) {
      const category = question.category;
      const { id, label, text, type } = question;
      
      let options;
      if (type === "text") {
        options = [inputText[label] || " "];
      } else {
        // Get the base options selected by the user
        options = [...new Set(answers[label] || [])];
        
        // Add special input texts for selected options
        const currentAnswer = answers[label] || [];
        
        // Handle "Other" text input for single-choice and multiple-choice differently
        if (currentAnswer.some(ans => ans.includes('Other') || ans.includes('أخرى'))) {
          if (otherText[label]) {
            if (type === "single-choice") {
              // For single-choice, ONLY send the user-entered text
              // The backend expects only the custom text value for single-choice
              options = [otherText[label]];
            } else {
              // For multiple-choice, filter out the "Other" option and add the custom text
              options = options.filter(opt => !(opt.includes('Other') || opt.includes('أخرى')));
              options.push(otherText[label]);
            }
          } else {
            // If "Other" is selected but no text provided, filter out the "Other" option
            options = options.filter(opt => !(opt.includes('Other') || opt.includes('أخرى')));
          }
        }
        
        // Add "Food" text if selected
        if (currentAnswer.some(ans => ans.includes('Food') || ans.includes('الطعام')) && foodText[label]) {
          if (type === "single-choice") {
            // For single-choice, ONLY send the user-entered text
            options = [foodText[label]];
          } else {
            // Filter out the "Food" option and add the custom text
            options = options.filter(opt => !(opt.includes('Food') || opt.includes('الطعام')));
            options.push(foodText[label]);
          }
        }
        
        // Add "Medications" text if selected
        if (currentAnswer.some(ans => ans.includes('Medications') || ans.includes('الأدوية')) && medicationsText[label]) {
          if (type === "single-choice") {
            // For single-choice, ONLY send the user-entered text
            options = [medicationsText[label]];
          } else {
            // Filter out the "Medications" option and add the custom text
            options = options.filter(opt => !(opt.includes('Medications') || opt.includes('الأدوية')));
            options.push(medicationsText[label]);
          }
        }
      }
      
      const newAnswer = {
        id,
        label,
        text,
        type,
        options
      };
      
      if (!updatedAnswersInCategory[category]) {
        updatedAnswersInCategory[category] = [];
      }
      
      updatedAnswersInCategory[category] = updatedAnswersInCategory[category].filter(
        (a) => a.id !== id
      );
      
      updatedAnswersInCategory[category].push(newAnswer);
    }
    
    // Filter out Women's Health for males
    if (!isFemale) {
      updatedAnswersInCategory = Object.keys(updatedAnswersInCategory).reduce(
        (filtered, category) => {
          if (category !== "Women's Health") {
            filtered[category] = updatedAnswersInCategory[category];
          }
          return filtered;
        },
        {}
      );
    }
    
    return updatedAnswersInCategory;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Prevent any form defaults that might cause navigation
    if (e && e.stopPropagation) e.stopPropagation();
    
    setFormLoading(true);
    setErrMsg(""); // Clear previous error messages
    
    // Validate the form data
    if (!validateFormData()) {
      setFormLoading(false);
      
      // Scroll to invalid question if exists
      if (invalidQuestionRef.current) {
        const invalidElement = document.getElementById(`question-${invalidQuestionRef.current}`);
        if (invalidElement) {
          invalidElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
      return;
    }
    
    // Build answers with special inputs included
    const updatedAnswersInCategory = buildAnswersWithSpecialInputs();
    setAnswersInCategory(updatedAnswersInCategory);
    console.log(updatedAnswersInCategory);
    
    // Choose which API to call based on whether we're in "I'm not feeling well" mode
    if (imNotFeelingWell) {
      // Call the "I'm not feeling well" API
      uploadingFeelingWell(
        updatedAnswersInCategory,
        setErrMsg,
        navigate,
        () => setFormLoading(false) // Callback to reset loading state on error
      );
    } else {
      // Call the regular questionnaire API with file upload
      uploadingQAandFile(
        files,
        updatedAnswersInCategory,
        setErrMsg,
        navigate,
        () => setFormLoading(false) // Callback to reset loading state on error
      );
    }
  };

  // Handle multiple choice checkbox change
  const handleCheckboxChange = (questionLabel, option) => {
    setAnswers(prev => {
      const currentAnswer = prev[questionLabel] || [];
      let newAnswers;
      
      if (option.includes("None of the above") || option.includes("None") || option.includes('لا شيء مما ذكر أعلاه') || option.includes("لا شيء") || option.includes("لا أستخدم")) {
        // If clicking on "None" option, either select only it or deselect all
        newAnswers = currentAnswer.includes(option) ? [] : [option];
      } else {
        // If clicking on a regular option
        const isSelected = currentAnswer.includes(option);
        
        if (isSelected) {
          // Deselecting an option
          newAnswers = currentAnswer.filter(ans => ans !== option);
          
          // If no options left, select "None" option
          if (newAnswers.length === 0) {
            const noneOption = filteredQuestions
              .find(q => q.label === questionLabel)?.options
              .find(opt => 
                opt.includes("None of the above") || opt.includes("None") || 
                opt.includes('لا شيء مما ذكر أعلاه') || opt.includes("لا شيء") || 
                opt.includes("لا أستخدم")
              );
            
            if (noneOption) {
              newAnswers = [noneOption];
            }
          }
        } else {
          // Selecting a new option - remove any "None" options
          newAnswers = [
            ...currentAnswer.filter(ans => 
              !(ans.includes("None of the above") || ans.includes("None") || 
                ans.includes('لا شيء مما ذكر أعلاه') || ans.includes("لا شيء") || 
                ans.includes("لا أستخدم"))), 
            option
          ];
        }
      }
      
      return {
        ...prev,
        [questionLabel]: newAnswers
      };
    });
  };

  // Handle single choice radio change
  const handleRadioChange = (questionLabel, option) => {
    setAnswers(prev => ({
      ...prev,
      [questionLabel]: [option]
    }));
  };

  // Handle text input change
  const handleTextChange = (questionLabel, value) => {
    setInputText(prev => ({
      ...prev,
      [questionLabel]: value
    }));
  };

  // Handle other text change for a specific question
  const handleOtherChange = (questionLabel, value) => {
    setOtherText(prev => ({
      ...prev,
      [questionLabel]: value
    }));
  };

  // Handle food allergies text change for a specific question
  const handleFoodChange = (questionLabel, value) => {
    setFoodText(prev => ({
      ...prev,
      [questionLabel]: value
    }));
  };

  // Handle medications text change for a specific question
  const handleMedicationsChange = (questionLabel, value) => {
    setMedicationsText(prev => ({
      ...prev,
      [questionLabel]: value
    }));
  };

  // Handle close button
  const handleClose = (event) => {
    event.preventDefault();
    setIsFileUploaded(false);
    setimNotFeelingWell(false);
    setFiles([]);
    setShowHealthDescriptionFirst(false);
    setHealthDescriptionSubmitted(false);
    setHealthDescription("");
    navigate("/LandingPage");
  };

  if (!questions || questions.length === 0) {
    return <LoadingSpinner />;
  }

  // Render a single choice question
  const renderSingleChoice = (question) => {
    const { id, label, text, options } = question;
    const currentAnswer = answers[label] || [];
    const isOtherSelected = currentAnswer.some(ans => 
      ans.includes('Other') || ans.includes('أخرى'));
    
    return (
      <div key={id} id={`question-${id}`} className="question fade-in">
        <label className="label-q fade-in">{text}</label>
        <div className="radio-container fade-in">
          {options.map((option, index) => {
            if ((option.includes('Other') || option.includes('أخرى'))) {
              return (
                <div key={index}>
                  {isOtherSelected ? (
                    <div className="other-input-container" style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        type="text"
                        name={`${label}-other`}
                        value={otherText[label] || ""}
                        onChange={(e) => handleOtherChange(label, e.target.value)}
                        placeholder={activeLang === 'ar' ? "يرجى التحديد" : "Please specify"}
                        className="other-input"
                        style={{ flexGrow: 1 }}
                      />
                      <button 
                        type="button" 
                        className="remove-option-btn"
                        onClick={() => handleRadioChange(label, options[0])}
                        style={{ 
                          border: 'none', 
                          background: 'transparent', 
                          cursor: 'pointer',
                          marginLeft: '8px',
                          color: '#888',
                          fontSize: '16px',
                          padding: '4px 8px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </div>
                  ) : (
                    <>
                      <input
                        type="radio"
                        id={`${label}-${index}`}
                        name={label}
                        value={option}
                        checked={currentAnswer.includes(option)}
                        onChange={() => handleRadioChange(label, option)}
                      />
                      <label htmlFor={`${label}-${index}`}>{option}</label>
                    </>
                  )}
                </div>
              );
            } else {
              return (
                <div key={index}>
                  <input
                    type="radio"
                    id={`${label}-${index}`}
                    name={label}
                    value={option}
                    checked={currentAnswer.includes(option)}
                    onChange={() => handleRadioChange(label, option)}
                  />
                  <label htmlFor={`${label}-${index}`}>{option}</label>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  };

  // Render a multiple choice question
  const renderMultipleChoice = (question) => {
    const { id, label, text, options } = question;
    const currentAnswer = answers[label] || [];
    
    const isOtherSelected = currentAnswer.some(ans => 
      ans.includes('Other') || ans.includes('أخرى'));
    const isFoodSelected = currentAnswer.some(ans => 
      ans.includes('Food') || ans.includes('الطعام'));
    const isMedicationSelected = currentAnswer.some(ans => 
      ans.includes('Medications') || ans.includes('الأدوية'));
    
    return (
      <div key={id} id={`question-${id}`} className="question fade-in">
        <label className="label-q fade-in">{text}</label>
        <div className="checkbox-container fade-in">
          {options.map((option, index) => {
            if (option.includes('Other') || option.includes('أخرى')) {
              return (
                <div key={index}>
                  {isOtherSelected ? (
                    <div className="other-input-container" style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        type="text"
                        name={`${label}-other`}
                        value={otherText[label] || ""}
                        onChange={(e) => handleOtherChange(label, e.target.value)}
                        placeholder={activeLang === 'ar' ? "يرجى التحديد" : "Other (please specify)"}
                        className="other-input"
                        style={{ flexGrow: 1 }}
                      />
                      <button 
                        type="button" 
                        className="remove-option-btn"
                        onClick={() => handleCheckboxChange(label, option)}
                        style={{ 
                          border: 'none', 
                          background: 'transparent', 
                          cursor: 'pointer',
                          marginLeft: '8px',
                          color: '#888',
                          fontSize: '16px',
                          padding: '4px 8px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </div>
                  ) : (
                    <>
                      <input
                        type="checkbox"
                        id={`${label}-${index}`}
                        name={label}
                        value={option}
                        checked={currentAnswer.includes(option)}
                        onChange={() => handleCheckboxChange(label, option)}
                      />
                      <label htmlFor={`${label}-${index}`}>{option}</label>
                    </>
                  )}
                </div>
              );
            } else if (option.includes('Food') || option.includes('الطعام')) {
              return (
                <div key={index}>
                  {isFoodSelected ? (
                    <div className="other-input-container" style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        type="text"
                        name={`${label}-food`}
                        value={foodText[label] || ""}
                        onChange={(e) => handleFoodChange(label, e.target.value)}
                        placeholder={activeLang === 'ar' ? "الطعام (يرجى التحديد)" : "Please specify Food Allergies"}
                        className="other-input"
                        style={{ flexGrow: 1 }}
                      />
                      <button 
                        type="button" 
                        className="remove-option-btn"
                        onClick={() => handleCheckboxChange(label, option)}
                        style={{ 
                          border: 'none', 
                          background: 'transparent', 
                          cursor: 'pointer',
                          marginLeft: '8px',
                          color: '#888',
                          fontSize: '16px',
                          padding: '4px 8px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </div>
                  ) : (
                    <>
                      <input
                        type="checkbox"
                        id={`${label}-${index}`}
                        name={label}
                        value={option}
                        checked={currentAnswer.includes(option)}
                        onChange={() => handleCheckboxChange(label, option)}
                      />
                      <label htmlFor={`${label}-${index}`}>{option}</label>
                    </>
                  )}
                </div>
              );
            } else if (option.includes('Medications') || option.includes('الأدوية')) {
              return (
                <div key={index}>
                  {isMedicationSelected ? (
                    <div className="other-input-container" style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        type="text"
                        name={`${label}-medications`}
                        value={medicationsText[label] || ""}
                        onChange={(e) => handleMedicationsChange(label, e.target.value)}
                        placeholder={activeLang === 'ar' ? "الأدوية (يرجى التحديد)" : "Please specify Medications"}
                        className="other-input"
                        style={{ flexGrow: 1 }}
                      />
                      <button 
                        type="button" 
                        className="remove-option-btn"
                        onClick={() => handleCheckboxChange(label, option)}
                        style={{ 
                          border: 'none', 
                          background: 'transparent', 
                          cursor: 'pointer',
                          marginLeft: '8px',
                          color: '#888',
                          fontSize: '16px',
                          padding: '4px 8px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </div>
                  ) : (
                    <>
                      <input
                        type="checkbox"
                        id={`${label}-${index}`}
                        name={label}
                        value={option}
                        checked={currentAnswer.includes(option)}
                        onChange={() => handleCheckboxChange(label, option)}
                      />
                      <label htmlFor={`${label}-${index}`}>{option}</label>
                    </>
                  )}
                </div>
              );
            } else {
              return (
                <div key={index}>
                  <input
                    type="checkbox"
                    id={`${label}-${index}`}
                    name={label}
                    value={option}
                    checked={currentAnswer.includes(option)}
                    onChange={() => handleCheckboxChange(label, option)}
                  />
                  <label htmlFor={`${label}-${index}`}>{option}</label>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  };

  // Render a number input question
  const renderNumberInput = (question) => {
    const { id, label, text } = question;
    const currentAnswer = answers[label] || [];
    
    return (
      <div key={id} id={`question-${id}`} className="question fade-in">
        <label className="label-q fade-in">{text}</label>
        <div className="number-container fade-in">
          <input
            type="number"
            name={label}
            value={currentAnswer[0] || ""}
            onChange={(e) => handleRadioChange(label, e.target.value)}
            min="0"
            max="200"
            required
          />
        </div>
      </div>
    );
  };

  // Render a text input question
  const renderTextInput = (question) => {
    const { id, label, text } = question;
    const prefilled = IspreFilled || imNotFeelingWell;
    const currentAnswer = answers[label] || [];
    
    // Special handling for health_description field in "I'm not feeling well" mode
    if (imNotFeelingWell && label === "health_description") {
      const defaultValue = healthDescription || currentAnswer[0] || "";
      
      return (
        <div key={id} id={`question-${id}`} className="fade-in">
          <label className="label-q fade-in">{text}</label>
          <textarea
            name={`${IspreFilled ? label : "answer"}`}
            defaultValue={defaultValue}
            onChange={(e) => handleTextChange(label, e.target.value)}
            rows={4}
            style={{
              width: '100%',
              padding: '10px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              marginBottom: '10px',
              resize: 'vertical'
            }}
            required
          />
        </div>
      );
    }
    
    // Regular text input for other fields
    const defaultVal = prefilled ? (currentAnswer[0] || "") : (inputText[label] || "");
    
    return (
      <div key={id} id={`question-${id}`} className="fade-in">
        <label className="label-q fade-in">{text}</label>
        <input
          type="text" 
          name={`${IspreFilled ? label : "answer"}`} 
          defaultValue={defaultVal}
          onChange={(e) => handleTextChange(label, e.target.value)}
          required
        />
      </div>
    );
  };

  // Render the appropriate question type
  const renderQuestion = (question) => {
    switch (question.type) {
      case "single-choice":
        return renderSingleChoice(question);
      case "multiple-choice":
        return renderMultipleChoice(question);
      case "number":
        return renderNumberInput(question);
      case "text":
        return renderTextInput(question);
      default:
        return null;
    }
  };

  // Render the health description screen
  const renderHealthDescriptionScreen = () => {
    return (
      <div className="question fade-in health-description-container" dir={activeLang === "ar" ? "rtl" : "ltr"}>
        <i
          className="fa fa-window-close icon-close-questionnaire"
          aria-hidden="true"
          onClick={handleClose}
        ></i>
        <div className="health-description-header">
          <i className="fas fa-heartbeat health-description-icon"></i>
          <h2 className="health-description-title">{lang.notFeelingWell}</h2>
        </div>
        <div className="header fade-in">
          <p className="health-description-prompt">{lang.describeYourHealth || "Please describe your health condition in detail:"}</p>
          <p className="health-description-sub-prompt">
            {activeLang === 'ar' 
              ? "وصف مفصل لحالتك سيساعدنا في تقديم تحليل أفضل. يرجى تضمين أي أعراض، ومتى بدأت، وأي علاجات جربتها."
              : "A detailed description of your condition will help us provide better analysis. Please include any symptoms, when they started, and any treatments you've tried."}
          </p>
        </div>
        <div className="health-description-form">
          <textarea
            className="health-description-textarea"
            value={healthDescription}
            onChange={(e) => setHealthDescription(e.target.value)}
            placeholder={activeLang === 'ar' ? "صف حالتك الصحية هنا... مثلا: أعاني من صداع وارتفاع في الحرارة منذ يومين" : "Describe your health condition here... E.g.: I've been experiencing headaches and fever for two days"}
            rows={8}
          />
          <div className="health-description-button-container">
            <button
              className="health-description-nextbtn"
              type="button" 
              onClick={handleHealthDescriptionSubmit}
            >
              {lang.Q_nextbtn} <i className="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {signInForVisitor ? (
        !isMobile ? <SignLogin /> : <SignLoginMobile />
      ) : (
        <>
          {loading ? (
            <LoadingResults />
          ) : (
            <div className="questionnaire-bg">
              <div className="questionnaire-header">
                <h1 className="questionnaire-title">{lang.Questionnaire}</h1>
              </div>
              <div className="container">
                <div className="centered-questionnaire">
                  {formLoading && <LoadingSpinner />}
                  {!formLoading && (
                    <>
                      {showHealthDescriptionFirst ? (
                        <div key="health-description-container">
                          {renderHealthDescriptionScreen()}
                        </div>
                      ) : (
                        <form
                          key="questionnaire-form"
                          className={`questionnaire-form ${
                            activeLang === "ar" ? "onlyRtl" : ""
                          }`}
                          data-testid="questionnaire"
                          onSubmit={handleSubmit}
                        >
                          <i
                            className="fa fa-window-close icon-close-questionnaire"
                            aria-hidden="true"
                            onClick={handleClose}
                          ></i>
                          {errMsg && (
                            <div className="error-message alert alert-danger fade-out">
                              {errMsg}
                            </div>
                          )}
                          
                          <div className="header fade-in">
                            <h2>{lang.ReviewAnswers}</h2>
                          </div>
                          
                          {/* Render all questions without category headers */}
                          {filteredQuestions.map(question => renderQuestion(question))}
                          
                          <br />
                          <div className={`${
                            activeLang === "ar" ? "flex-row-reverse" : "flex-row"
                          }`}>
                            <button
                              className="nextbtn"
                              type="submit"
                            >
                              {lang.Q_submitbtn}
                            </button>
                          </div>
                        </form>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
} 