import axios from '../api/axios';
import { useData } from '../api/store';
import toast from 'react-hot-toast';
import translations from '../translations';

const FILL_QUESTIONNAIRE_URL = process.env.REACT_APP_BASE_URL + "/api/get_lab_result/";

export const uploadingQAandFile = async (files, answers, setErrMsg, navigate, onErrorCallback) => {
    const { activeLang, setResults, setIsFileUploaded, setLoading, setSuccessMsg, setSignInForVisitor, setUploadAgain } = useData.getState();
    const lang = translations[activeLang];

    // Validate answers object
    if (!answers || Object.keys(answers).length === 0) {
        setErrMsg(lang.MissingAnswers || "Please fill in all required fields.");
        if (onErrorCallback) onErrorCallback();
        return;
    }

    // Make sure files exist
    if (!files) {
        setErrMsg(lang.MissingFile || "No file uploaded. Please upload your lab test first.");
        if (onErrorCallback) onErrorCallback();
        return;
    }

    setLoading(true);
    try {
        files.append('history', JSON.stringify(answers, null, 2));
        const response = await axios.post(FILL_QUESTIONNAIRE_URL, files, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
        });
        if (response.status === 200) {
            toast.success('Your Results is ready !!!');
            setResults(response.data);
            navigate('/Results');
        }
    } catch (error) {
        if (error.response) {
            if (error.response.status === 403) {
                setLoading(false);
                setSignInForVisitor(true);
                toast.error(lang.UserNotLoggedIn);
                if (onErrorCallback) onErrorCallback();
                return;
            } else if (error.response.status === 400) {
                setLoading(false);
                setErrMsg(error.response.data?.message || error.message || lang.InvalidData);
                toast.error(error.response.data?.message || error.message || lang.InvalidData);
                if (onErrorCallback) onErrorCallback();
                return;
            } else {
                setLoading(false);
                setErrMsg(error.response.data?.message || error.message || lang.ServerError);
                toast.error(error.response.data?.message || error.message || lang.ServerError);
                if (onErrorCallback) onErrorCallback();
                return;
            }
        } else {
            setLoading(false);
            console.error(error);
            setErrMsg(lang.NetworkError);
            toast.error(lang.NetworkError);
            if (onErrorCallback) onErrorCallback();
            return;
        }
    }
};
