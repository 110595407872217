import { useData } from '../api/store';
import toast from 'react-hot-toast';
import axios from 'axios';
import translations from '../translations';
import { setAuthTokenCookie } from './setAuthTokenCookie';
const GET_USER_TOKEN = process.env.REACT_APP_BASE_URL + "/api/register_user/";

export async function handleFacebookSignUp(response, navigate) {
  const {activeLang , setLoggedIn , setConsent , setLoggedInUser, signInForVisitor , setSignInForVisitor, setSignUpActive} = useData.getState();
  const { email, accessToken, name } = response;
  const lang = translations[activeLang];

  try {
    const response = await axios.post(
      GET_USER_TOKEN,
      { 
        vendor: "facebook",
        email: email,
        access_token: accessToken,
        name: name,
        consented: "True",
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );

    if (response.status === 200 || response.status === 201) {
      setAuthTokenCookie(response.data.token);
      toast.success(lang.LoggedIn)
      setLoggedInUser({
        username : response.data.name,
        email : response.data.email,
      })
      if (signInForVisitor)
        {
          setSignInForVisitor(false);
          return ;
        } else {
            navigate(-1);
          }
    }
  } catch (error) {
    if (error.response){
    if (error.response.status === 409){
      toast.error(lang.AlreadySignedIn)
      setSignUpActive(true);
      } else if (error.response.status === 403) {
          toast.error(lang.Forbidden);
      } else if (error.response.status === 400) {
          toast.error(lang.BadRequest);
      } else if (error.response.status === 500)
          toast.error(lang.ServerError);
  }else 
    toast.error(lang.NetworkError);
}
setLoggedIn(false);
setConsent(false);
}
