import React, { useEffect, useState, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import axios from './api/axios';
import { useData } from './api/store';
import CookieConsent from 'react-cookie-consent';
import FeedbackModal from './FeedBackModal';
import logo from '../res/1-cropped.svg';
import './style/Navbar.css';
import SettingsPanel from './SettingsPanel/SettingsPanel';
import LanguageSwitcher from './LanguageSwitcher';
import translations from './translations';
import toast from 'react-hot-toast';

const USER_INFO_URL = process.env.REACT_APP_BASE_URL + '/api/get_user_info/';

function Navbar() {
  const { isLoggedIn, 
    isFileUploaded, 
    setEmail,
    setUser,
    setBirthday,
    setActiveLang,
    setSubscribed,
    setVendor,
    setFetchUserInfo,
    fetchUserInfo,
    setCountry,
    activeLang,
    setPreFilledQuestions,
    preFilledQuestions,
    setIspreFilled} = useData();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const navbarCollapseRef = useRef(null);
  const lang = translations[activeLang];

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(USER_INFO_URL, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.status === 200 || response.status === 201) {
          setUser(response.data.name);
          setEmail(response.data.email);
          setBirthday(response.data.birthday);
          setActiveLang(response.data.language);
          setSubscribed(response.data.subscribed);
          setCountry(response.data.country);
          setVendor(response.data.vendor);
          if (response.data.questions){
            try {
              setPreFilledQuestions(response.data.questions);
              setIspreFilled(true);
            } catch {
              setPreFilledQuestions({});
            }
          }
        } else if (response.status === 403) {
          if (isLoggedIn)
            window.location.reload();
        }
      } catch (error) {
        setEmail("Loading ...");
        setUser("Loading ...");
        if (isLoggedIn)
          window.location.reload();
      }
    };
      fetchUserData();
      setFetchUserInfo(false);
  }, [fetchUserInfo]);


  useEffect(() => {
  },[preFilledQuestions])

  const toggleSettings = () => {
    setIsExpanded(!isExpanded);
  };

  const handleNavLinkClick = () => {
    if (navbarCollapseRef.current && navbarCollapseRef.current.classList.contains('show')) {
      navbarCollapseRef.current.classList.remove('show');
    }
  };

  function SettingButton() {
    return (
      <div className="navbar-avatar">
        <i className="fas fa-users-cog avatar-icon" onClick={toggleSettings}>
        </i>
          <span className='setting-profile-btn' onClick={toggleSettings}> Profile</span>
      </div>
    );
  }

  function SignInLink() {
    return (
      <div className="navbar-avatar">
        
        <Link className="sign-in-link" to="/SignLogin" onClick={handleNavLinkClick}>
        <i className="fa fa-sign-in-alt avatar-icon"></i>
          {lang.signIn}
          </Link>
      </div>
    );
  }

  const openFeedbackModal = () => setIsFeedbackModalOpen(true);
  const closeFeedbackModal = () => setIsFeedbackModalOpen(false);

  return (
    <>
      {!isFileUploaded &&
        <nav className="navbar navbar-expand-lg text-light custom-navbar" >
          <div className="container-fluid">
            <Link className="navbar-brand-custom" to="/LandingPage">
              <img src={logo} className='logo' alt="Logo"></img>
            </Link>
            <div className="collapse navbar-collapse" id="navbarSupportedContent" ref={navbarCollapseRef}>
              {!isExpanded && (
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <NavLink className="nav-link-inactive" to="/LandingPage" onClick={handleNavLinkClick}>{lang.home}</NavLink>
                  </li>
                  {isLoggedIn && <li className="nav-item">
                    <NavLink className="nav-link-inactive" to="/History" onClick={handleNavLinkClick}>{lang.HistoryTap}</NavLink>
                  </li>}
                  <li className="nav-item">
                    <NavLink className="nav-link-inactive" to="/AboutUs" onClick={handleNavLinkClick}>{lang.whoAreWe}</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link-inactive" to="/ContactUs" onClick={handleNavLinkClick}>{lang.contactUs}</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link-inactive" to="/PrivacyPolicy" onClick={handleNavLinkClick}>{lang.privacyPolicy}</NavLink>
                  </li>
                </ul>
              )}
              <div className='center-btns-in-mobile'>
                <button onClick={openFeedbackModal} className="feedback-navbar-button">
                  {lang.feedback} <i className='fa fa-comments'></i>
                </button>
              </div>
                <LanguageSwitcher />
            </div>
              {isLoggedIn ? <SettingButton /> : <SignInLink />}
              {!isExpanded && (
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="fa fa-sliders"></span>
              </button>
            )}
          </div>
          {isExpanded && <SettingsPanel toggleSettings={toggleSettings} />}
        </nav>
      }
      <a className="support-navbar-button" href='https://ko-fi.com/D1D4128L6U'
       target="_blank" 
       rel="noopener noreferrer">
        {lang.supportTahlili}
         <i className="fa-solid fa-circle-dollar-to-slot"></i>
      </a>
      <CookieConsent
        location="bottom"
        buttonText={lang.Agree}
        cookieName="TahliliCookiesConsent"
        style={{ background: "#2B373B", height: "240px", zIndex: "9999" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={60}
      >
        {lang.cookiesConsent}{" "}
        <a href="/privacyPolicy" style={{ color: "#61dafb" }}>{lang.learnMore}</a>
      </CookieConsent>
      <FeedbackModal isOpen={isFeedbackModalOpen} onRequestClose={closeFeedbackModal} />
    </>
  );
}

export default Navbar;
