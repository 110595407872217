import React from 'react';
import './style/AboutUs.css';
import { AiOutlineInstagram, AiOutlineSmallDash } from "react-icons/ai";
import { FaFacebook, FaLinkedin } from "react-icons/fa6";
import doctorImage from '../res/home1.png'; 
import translations from './translations';
import { useData } from './api/store';

const AboutUs = () => {
  const { activeLang } = useData();
  const lang = translations[activeLang];

  return (
    <div className={`about-us ${activeLang === 'ar' ? 'rtl ' : ''}`}>
      <div className="colored-rectangle"></div>
      <div className="about-us-content">
        <h1>{lang.aboutTitle}</h1>
        {/* <div className='about-dots'>
          <AiOutlineSmallDash />
        </div> */}
        <p>{lang.aboutUsDescription}</p>
        <div className="social-media">
          <h3>{lang.socialMedia}</h3>
          <div className={`main-about-us ${activeLang === 'ar' ? 'ltr ' : ''}`}>
  <div className="up">
    <a href="https://www.instagram.com/p/C_OkAIxs1Qr/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==">
    <button className="card1">
      <svg 
        className="instagram" 
        fillRule="nonzero" 
        height="30px" 
        width="30px" 
        viewBox="0 0 256 256" 
        xmlns="http://www.w3.org/2000/svg"
      >
        <g style={{ mixBlendMode: "normal" }} textAnchor="none" fontSize="none" fontWeight="none" fontFamily="none" strokeDashoffset="0" strokeDasharray="" strokeMiterlimit="10" strokeLinejoin="miter" strokeLinecap="butt" strokeWidth="1" stroke="none" fillRule="nonzero">
          <g transform="scale(8,8)">
            <path d="M11.46875,5c-3.55078,0 -6.46875,2.91406 -6.46875,6.46875v9.0625c0,3.55078 2.91406,6.46875 6.46875,6.46875h9.0625c3.55078,0 6.46875,-2.91406 6.46875,-6.46875v-9.0625c0,-3.55078 -2.91406,-6.46875 -6.46875,-6.46875zM11.46875,7h9.0625c2.47266,0 4.46875,1.99609 4.46875,4.46875v9.0625c0,2.47266 -1.99609,4.46875 -4.46875,4.46875h-9.0625c-2.47266,0 -4.46875,-1.99609 -4.46875,-4.46875v-9.0625c0,-2.47266 1.99609,-4.46875 4.46875,-4.46875zM21.90625,9.1875c-0.50391,0 -0.90625,0.40234 -0.90625,0.90625c0,0.50391 0.40234,0.90625 0.90625,0.90625c0.50391,0 0.90625,-0.40234 0.90625,-0.90625c0,-0.50391 -0.40234,-0.90625 -0.90625,-0.90625zM16,10c-3.30078,0 -6,2.69922 -6,6c0,3.30078 2.69922,6 6,6c3.30078,0 6,-2.69922 6,-6c0,-3.30078 -2.69922,-6 -6,-6zM16,12c2.22266,0 4,1.77734 4,4c0,2.22266 -1.77734,4 -4,4c-2.22266,0 -4,-1.77734 -4,-4c0,-2.22266 1.77734,-4 4,-4z"></path>
          </g>
        </g>
      </svg>
    </button>
    </a>
    <a href="https://www.facebook.com/profile.php?id=61558580654350">
    <button className="card2" >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="24" className="facebook" width="24">
        <path d="M9.19795 21.5H13.198V13.4901H16.8021L17.198 9.50977H13.198V7.5C13.198 6.94772 13.6457 6.5 14.198 6.5H17.198V2.5H14.198C11.4365 2.5 9.19795 4.73858 9.19795 7.5V9.50977H7.19795L6.80206 13.4901H9.19795V21.5Z"></path>
      </svg>
    </button>
    </a>
  </div>
  <div className="down">
    <button className="card3">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-linkedin linkedin" viewBox="0 0 24 24">
        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
    </svg>
    </button>
    <a href="https://x.com/TahliliSol/status/1828898590032314753">
    <button className="card4">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 24 24">
  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
</svg>
    </button>
    </a>
  </div>
</div>
    
  </div>
</div>
<div className="about-us-image">
  <img src={doctorImage} alt="Doctor" />
</div>
</div>
  );
};

export default AboutUs;
