import React, {useEffect, useState} from 'react';
import { useData } from '../api/store';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../style/History.css';
import translations from '../translations';
import toast from 'react-hot-toast';
import LoadingPage from '../LoadingPage';
const GET_HISTORY_URL = process.env.REACT_APP_BASE_URL + "/api/get_result_history/";

export function LabHistory() {
    const { resultshistory, activeLang , setResults , setResultshistory} = useData();
    const lang = translations[activeLang];
    const [ loading , setLoading ] = useState(false);
    // const [ refresh , setRefresh ] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchResultsHistory() {
            try {
                setLoading(true);
                const response = await axios.get(GET_HISTORY_URL, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (response.status === 200) {
                    setResultshistory(response.data);
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                toast.error("Failed to fetch history. Please try again.");
            }
        }

        fetchResultsHistory();
    }, []);

    const handleTransactionClick = (date, data) => {
        if (!data)
        {
            toast.error("Results is not avaliabe now.. please try again later");
            return ;
        }
        setResults(data);
        navigate("/Results");
    };

    const splitDate = (dateTime) =>{
        const date = dateTime.split(' ')[0];
      return date;
    }

    return (
        <>
        {loading && <LoadingPage />}
            <div className="history-container">
                <div className="history-summary-section">
                    <div className="history-title">
                        <h2>{lang.HistoryH1}</h2>
                    </div>
                    <div className="history-info">
                        <i className="fa-solid fa-flask-vial"></i>
                    </div>
                </div>
                <div className="history-details-section">
                    <div className="history-list">
                        {Object.keys(resultshistory).length === 0 ? (
                            <p>{lang.NohistoryFound}</p>
                        ) : (
                            Object.keys(resultshistory).map((date, index) => (
                                <div 
                                    key={index} 
                                    className="history-card"     
                                >
                                    <i className="fa-solid fa-file-waveform"></i>
                                    <div className="history-card-details">
                                        <span className="history-status">Date: {splitDate(date)}</span>
                                    </div>
                                    <button className="history-issue-button" onClick={() => handleTransactionClick(date, resultshistory[date])}>{lang.VeiwResult}</button>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
