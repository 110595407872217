import React, { useEffect } from 'react';
import { useData } from '../api/store';

export const MultipleChoice = ({ question, currentAnswer, setAnswers, currentQuestionIndex, specifyOptions }) => {
  const { otherText, foodText, medicationsText, handleOtherChange, handleFoodChange, handleMedicationsChange } = specifyOptions;
  const { activeLang , IspreFilled} = useData();

  useEffect(() => {
    if (IspreFilled && currentAnswer?.length > 0) {
      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        [currentQuestionIndex]: currentAnswer,
      }));
    } else if ((currentAnswer?.length < 1 || currentAnswer?.length === undefined) && question.options.length > 0) {
      const defaultOption = question.options.includes('None of the above')
        ? 'None of the above'
        : question.options.includes('لا شيء مما ذكر أعلاه') ? 'لا شيء مما ذكر أعلاه' 
        : question.options[0];
  
      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        [currentQuestionIndex]: [defaultOption],
      }));
    }
  }, [IspreFilled, currentAnswer, question.options, currentQuestionIndex, setAnswers]);

  const handleCheckboxChange = (option) => {
    let newAnswers;

    if (option === "None of the above" || option === "None" || option === 'لا شيء مما ذكر أعلاه') {
      newAnswers = currentAnswer?.includes(option) ? [] : [option];
    } else {
      newAnswers = currentAnswer?.includes(option)
        ? currentAnswer.filter((ans) => ans !== option)
        : [...currentAnswer.filter((ans) => ans !== "None of the above" && ans !== "None" && ans !== 'لا شيء مما ذكر أعلاه'), option];
    }

    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [currentQuestionIndex]: newAnswers,
    }));
  };

  const isOtherSelected = currentAnswer?.includes('Other (please specify)') || currentAnswer?.includes('أخرى (يرجى التحديد)');
  const isFoodSelected = currentAnswer?.includes('Food (please specify)') || currentAnswer?.includes('الطعام (يرجى التحديد)');
  const isMedicationSelected = currentAnswer?.includes('Medications (please specify)') || currentAnswer?.includes('الأدوية (يرجى التحديد)');

  return (
    <div key={question.id}>
      <label className='label-q fade-in'>{question.text}</label>
      <div className="checkbox-container fade-in">
        {question.options.map((option, index) => (
          <div key={index}>
            {(option === 'Other (please specify)' || option === 'أخرى (يرجى التحديد)') && isOtherSelected ? (
              <div className="other-input-container">
                <input
                  type="text"
                  name={`${IspreFilled ? question.label : "answer"}`}
                  value={otherText}
                  onChange={handleOtherChange}
                  placeholder={activeLang === 'en' ? "Other (please specify)" : "(أخرى (يرجى التحديد"}
                  className="other-input"
                />
              </div>
            ) : (option === 'Food (please specify)' || option === 'الطعام (يرجى التحديد)') && isFoodSelected ? (
              <div className="other-input-container">
                <input
                  type="text"
                  name={`${IspreFilled ? question.label : "answer"}`}
                  value={foodText}
                  onChange={handleFoodChange}
                  placeholder={activeLang === 'en' ?"Please specify Food Allergies" : "الطعام (يرجى التحديد)"}
                  className="other-input"
                />
              </div>
            ) : (option === 'Medications (please specify)' || option === 'الأدوية (يرجى التحديد)') && isMedicationSelected ? (
              <div className="other-input-container">
                <input
                  type="text"
                  name={`${IspreFilled ? question.label : "answer"}`}
                  value={medicationsText}
                  onChange={handleMedicationsChange}
                  placeholder={activeLang === 'en' ? "Please specify Medications" : "الأدوية (يرجى التحديد)"}
                  className="other-input"
                />
              </div>
            ) : (
              <>
                <input
                  type="checkbox"
                  id={`${question.label}-${index}`}
                  name={`${IspreFilled ? question.label : "answer"}`}
                  value={option}
                  checked={currentAnswer?.includes(option)}
                  onChange={() => handleCheckboxChange(option)}
                />
                <label htmlFor={`${question.label}-${index}`}>{option}</label>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
