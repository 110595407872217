import React, { useEffect, useRef, useState } from 'react';
import TypingAnimation from './TypingAnimation';
import { useData } from '../api/store';
import translations from '../translations';
import '../style/Chatbox.css';
import toast from 'react-hot-toast';

const CHAT_URL = process.env.REACT_APP_BASE_URL + "/api/chat/";

const ChatBox = ({ results }) => {
  const { activeLang } = useData();
  const lang = translations[activeLang];
  const [messages, setMessages] = useState([
    { sender: 'ai', text: lang.chatAiMsg }
  ]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [userInteracted, setUserInteracted] = useState(false);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);

  const ApiResponse = async (userMessage) => {
    try {
      const response = await fetch(CHAT_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          message: userMessage,
          result: results
        }),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';

      setMessages(prevMessages => [
        ...prevMessages,
        { sender: 'ai', text: '' }
      ]);

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        
        buffer += decoder.decode(value, { stream: true });
        const lines = buffer.split('\n');
        buffer = lines.pop(); // Save incomplete line

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const data = line.slice(6).trim();
            if (data === '[DONE]') {
              return; // Stream is finished
            }
            try {
              const parsedData = JSON.parse(data);
              if (parsedData.content) {
                setMessages(prevMessages => {
                  const newMessages = [...prevMessages];
                  const lastMessage = newMessages[newMessages.length - 1];
                  lastMessage.text += parsedData.content;
                  return newMessages;
                });
              }
            } catch (error) {
              console.error('Error parsing JSON:', error);
            }
          }
        }
      }
    } catch (error) {
      console.error("AI response error:", error);
      toast.error('Error communicating with AI. Please try again.');
    }
  };

  const handleSendMessage = async () => {
    if (!input.trim()) return;

    const userMessage = { sender: 'user', text: input };
    setMessages(prevMessages => [...prevMessages, userMessage]);
    setInput('');
    setLoading(true);

    try {
      await ApiResponse(input);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !loading) {
      setUserInteracted(true);
      handleSendMessage();
    }
  };

  useEffect(() => {
    if (userInteracted) {
      scrollToBottom();
    }
  }, [messages, userInteracted]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const formatMessage = (text) => {
    // Detect if the text is RTL (simple check for Arabic characters)
    const isRTL = /[\u0600-\u06FF]/.test(text);
    
    // Convert numbered lists to HTML ordered list
    text = text.replace(/(\d+)\.\s(.*?)(?=\n\d+\.|\n\n|$)/gs, '<li>$2</li>');
    text = text.replace(/<li>/g, '<ol><li>').replace(/<\/li>(?!<li>)/g, '</li></ol>');
    
    // Convert bullet points to HTML unordered list
    text = text.replace(/•\s(.*?)(?=\n•|\n\n|$)/gs, '<li>$1</li>');
    text = text.replace(/<li>/g, '<ul><li>').replace(/<\/li>(?!<li>)/g, '</li></ul>');
    
    // Convert bold text
    text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    
    // Convert paragraphs
    text = text.replace(/\n\n/g, '</p><p>');
    
    // Add RTL class if necessary
    const rtlClass = isRTL ? ' class="rtl"' : '';
    
    return `<div${rtlClass}><p>${text}</p></div>`;
  };

  return (
    <div className="chat-box" id="chatbox">
      <div className="chat-messages">
        {messages.map((msg, index) => (
          <div key={index} className={`message-div ${msg.sender}`}>
            <div className={`message ${msg.sender}`}>
              {msg.sender === 'ai' ? (
                <div dangerouslySetInnerHTML={{ __html: formatMessage(msg.text) }} />
              ) : (
                <div className={/[\u0600-\u06FF]/.test(msg.text) ? 'rtl' : ''}>{msg.text}</div>
              )}
            </div>
          </div>
        ))}
        {loading && <TypingAnimation />}
        <div ref={messagesEndRef} />
      </div>
      <div className={`chat-input ${activeLang ? "onlyRtl" : ""}`}>
        <input
          type="text"
          ref={inputRef}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={lang.placeHolderchatAiMsg}
        />
        <button className="chatBox-sendBtn" onClick={handleSendMessage} disabled={loading}>
          <i className="fa-solid fa-circle-arrow-up"></i>
        </button>
      </div>
    </div>
  );
};

export default ChatBox;
