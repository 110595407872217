import axios from "../api/axios";
import { useData } from "../api/store";

const LOGGIN_CHECK_URL = process.env.REACT_APP_BASE_URL + "/api/is_loggedin/";

export async function checkIsLogin() {
    const { setLoggedIn, setConsent } = useData.getState();
    try {
        const userTokenResponse = await axios.get(LOGGIN_CHECK_URL, {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (userTokenResponse.status === 200) {
            setLoggedIn(true);
            setConsent(true);
            return true;
        } else if (userTokenResponse.status === 403) {
            setLoggedIn(false);
            setConsent(false);
            return false;
        } else {
            setConsent(false);
            return false;
        }
    } catch {
        setLoggedIn(false);
        setConsent(false);
        return false;
    }
}
